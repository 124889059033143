import React from 'react';

const SvgSideTop = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M21 6H3V4h18v2Zm-6 6H9v6h6v-6Zm-8-2h10v10H7V10Z" />
    </svg>
);
export default SvgSideTop;
