import * as React from 'react';

const SvgIcon = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.995 5.99a1.53 1.53 0 1 0 0 3.06 1.53 1.53 0 0 0 0-3.06Zm-.53 1.53a.53.53 0 1 1 1.06 0 .53.53 0 0 1-1.06 0ZM7.465 7.52a1.53 1.53 0 1 1 3.06 0 1.53 1.53 0 0 1-3.06 0Zm1.53-.53a.53.53 0 1 0 0 1.06.53.53 0 0 0 0-1.06ZM7.463 16.187A6.43 6.43 0 0 0 12 18.07a6.43 6.43 0 0 0 6.42-6.42v-.15a.5.5 0 0 0-.5-.5H6.08a.5.5 0 0 0-.5.5v.15a6.43 6.43 0 0 0 1.883 4.537Zm.823-.569A5.42 5.42 0 0 1 6.59 12h10.82a5.42 5.42 0 0 1-9.124 3.618Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.995 2a9.52 9.52 0 1 0 0 19.04 9.52 9.52 0 0 0 0-19.04Zm-8.52 9.52a8.52 8.52 0 1 1 17.04 0 8.52 8.52 0 0 1-17.04 0Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgIcon;
