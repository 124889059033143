import * as React from 'react';

const SvgAttachment = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12 22a4.5 4.5 0 0 1-4.49-4.49v-12a3.52 3.52 0 1 1 7 0v11a2.55 2.55 0 0 1-5.1 0v-6a.5.5 0 0 1 1 0v6a1.55 1.55 0 1 0 3.1 0v-11a2.52 2.52 0 1 0-5 0v12a3.5 3.5 0 1 0 7 0v-7A.5.5 0 0 1 16 10a.5.5 0 0 1 .5.5v7A4.5 4.5 0 0 1 12 22Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgAttachment;
