import React from 'react';

const SvgCloudSyncPaused = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#cloudSyncPaused_svg__a)">
            <path d="M12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11A2.98 2.98 0 0 1 22 15c0 1.65-1.35 3-3 3H6c-2.21 0-3.999-1.79-3.999-3.999 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95A5.47 5.47 0 0 1 12.002 6L12 6Zm0-2C9.11 4 6.6 5.64 5.35 8.04A5.995 5.995 0 0 0 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96A7.49 7.49 0 0 0 12 4Z" />
            <path d="M9 10h2v6H9v-6ZM13 10h2v6h-2v-6Z" />
        </g>
        <defs>
            <clipPath id="cloudSyncPaused_svg__a">
                <path d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgCloudSyncPaused;
