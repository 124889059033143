import React from 'react';

const SvgShadow = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M3 5H1v16c0 1.1.9 2 2 2h16v-2H3V5Zm18-4H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2Zm0 16H7V3h14v14Z" />
    </svg>
);
export default SvgShadow;
