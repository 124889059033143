import React from 'react';

const SvgWidthHalf = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M4 18h5V6H4v12ZM2 5a1 1 0 0 1 1-1h8v16H3a1 1 0 0 1-1-1V5Zm13 1v12h5V6h-5Zm-2 14V4h8a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-8Z" />
    </svg>
);
export default SvgWidthHalf;
