import React from 'react';

const SvgFile = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M21 11.01 3 11v2h18v-1.99ZM3 16h12v2H3v-2ZM21 6H3v2.01L21 8V6Z" />
    </svg>
);
export default SvgFile;
