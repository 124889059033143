import * as React from 'react';

const SvgLinks = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.783 14.395c.446.184.924.277 1.407.275a3.65 3.65 0 0 0 2.6-1.07l3.65-3.6a3.69 3.69 0 0 0-1.168-6.02 3.688 3.688 0 0 0-4.022.78l-3.1 3.09a.504.504 0 0 0 .163.819.502.502 0 0 0 .547-.109L15 5.47a2.67 2.67 0 0 1 3.73 3.77l-3.65 3.65a2.68 2.68 0 0 1-3.78 0 .502.502 0 0 0-.71.71 3.65 3.65 0 0 0 1.193.795Z"
            fill="#393B3D"
        />
        <path
            d="M5.767 20.396a3.62 3.62 0 0 0 1.403.274 3.65 3.65 0 0 0 2.6-1.07l3.09-3.09a.502.502 0 0 0-.71-.71l-3.09 3.09a2.67 2.67 0 0 1-3.78-3.77l3.66-3.65a2.65 2.65 0 0 1 3.77 0 .51.51 0 0 0 .71 0 .498.498 0 0 0 0-.71 3.68 3.68 0 0 0-5.19 0l-3.65 3.65a3.68 3.68 0 0 0 0 5.19 3.62 3.62 0 0 0 1.187.796Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgLinks;
