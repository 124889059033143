import React from 'react';

const SvgVersions = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1Zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.101.891 2.001 1.99 2.001H19c1.1 0 2-.9 2-2V11l-6-6ZM8 21V7h6v5h5v9H8Z" />
    </svg>
);
export default SvgVersions;
