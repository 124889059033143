import * as React from 'react';

const SvgDragAndDrop = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.16 10V6.276l.73.724a.49.49 0 0 0 .35.15.5.5 0 0 0 .36-.14.51.51 0 0 0 0-.71l-1.59-1.59a.5.5 0 0 0-.7 0L8.72 6.29a.502.502 0 1 0 .71.71l.73-.724V10H6.44l.73-.73a.5.5 0 0 0-.355-.854.51.51 0 0 0-.355.144l-1.58 1.58a.55.55 0 0 0-.15.36.51.51 0 0 0 .15.35l1.58 1.58a.51.51 0 0 0 .36.15.5.5 0 0 0 .35-.85L6.44 11h3.72v3.72l-.73-.73a.498.498 0 0 0-.71.7l1.59 1.59a.5.5 0 0 0 .35.14.5.5 0 0 0 .34-.14l1.6-1.58a.5.5 0 0 0-.355-.848.5.5 0 0 0-.355.148l-.73.724V11h3.72l-.73.73a.501.501 0 0 0 .35.85.51.51 0 0 0 .36-.15l1.58-1.58a.51.51 0 0 0 0-.71l-1.58-1.58a.502.502 0 0 0-.71.71l.73.73h-3.72Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.05 18.61h13.22a1.5 1.5 0 0 0 1.5-1.5V3.89a1.5 1.5 0 0 0-1.5-1.5H4.05a1.5 1.5 0 0 0-1.5 1.5v13.22a1.5 1.5 0 0 0 1.5 1.5ZM3.696 3.536a.5.5 0 0 1 .354-.146h13.22a.5.5 0 0 1 .5.5v13.22a.51.51 0 0 1-.5.5H4.05a.51.51 0 0 1-.5-.5V3.89a.5.5 0 0 1 .146-.354Z"
            fill="#393B3D"
        />
        <path
            d="M20.066 21.773a.5.5 0 0 0 .354.147 1.5 1.5 0 0 0 1.51-1.51V20a.5.5 0 0 0-1 0v.42a.5.5 0 0 1-.51.5.5.5 0 0 0-.354.853ZM16.42 21.92h1.99a.5.5 0 1 0 .01-1h-2a.5.5 0 0 0 0 1ZM12.42 21.92h1.99a.5.5 0 1 0 .01-1h-2a.5.5 0 0 0 0 1ZM10.41 21.92a.5.5 0 0 0 .5-.52.5.5 0 0 0-.5-.5h-.84a.49.49 0 0 1-.46-.3.5.5 0 0 0-.65-.26.5.5 0 0 0-.26.66 1.48 1.48 0 0 0 1.37.92h.84ZM21.066 18.363A.5.5 0 0 0 21.92 18v-2a.5.5 0 0 0-1 0v2.01a.5.5 0 0 0 .146.353ZM21.066 14.364A.5.5 0 0 0 21.92 14v-2a.5.5 0 0 0-1 0v2.01a.5.5 0 0 0 .146.354ZM21.066 10.364A.5.5 0 0 0 21.92 10v-.42a1.5 1.5 0 0 0-1.5-1.5.5.5 0 0 0 0 1 .5.5 0 0 1 .5.5v.43a.5.5 0 0 0 .146.354Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgDragAndDrop;
