import React from 'react';
import { bool, func, number, oneOf, string } from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'uikit/icons';

const inputStyles = {
    primary: 'primary',
    secondary: 'secondary',
    tertiary: 'tertiary',
};

function TextInput({
    testId,
    className,
    value,
    onChange,
    onKeyPress,
    onFocus,
    onBlur,
    placeholder,
    autoFocus,
    autoComplete,
    name,
    type,
    isTextArea,
    isDisabled,
    shouldSpellcheck,
    shouldValidate,
    shouldPreventAllSpaces,
    icon,
    rows,
    inputStyle,
    maxLength,
    height,
    hasError,
    isReadOnly,
    useFloatingPlaceholder,
}) {
    const Input = isTextArea ? 'textarea' : 'input';

    const renderInput = () => (
        <Input
            className={classnames('text-input', {
                'is-text-area': isTextArea,
                'is-primary': inputStyle === inputStyles.primary,
                'is-secondary': inputStyle === inputStyles.secondary,
                'is-tertiary': inputStyle === inputStyles.tertiary,
                'has-error': hasError,
                [className]: className && !icon,
            })}
            value={value || ''}
            onChange={({ target }) => {
                // prevent the user from entering/pasting nothing but spaces?
                const input = shouldPreventAllSpaces && !target.value.trim() ? '' : target.value;

                if (!shouldValidate) return onChange(input);

                return onChange(input, target.checkValidity());
            }}
            placeholder={placeholder}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            onKeyPress={onKeyPress}
            onFocus={onFocus}
            onBlur={onBlur}
            name={name}
            type={type}
            disabled={isDisabled}
            spellCheck={shouldSpellcheck}
            rows={rows}
            required={shouldValidate ? 'required' : undefined}
            maxLength={maxLength}
            style={{ height: height ? `${height}px` : null }}
            readOnly={isReadOnly}
            data-testid={testId}
        />
    );

    if (icon) {
        return (
            <div className={classnames('text-input-wrapper', className)}>
                <Icon icon={icon} className="text-input-icon" />
                {renderInput()}
            </div>
        );
    }

    if (useFloatingPlaceholder) {
        return (
            <div className="is-floating-placeholder">
                {renderInput()}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>{placeholder}</label>
            </div>
        );
    }

    return renderInput();
}

TextInput.propTypes = {
    className: string,
    value: string,
    onChange: func.isRequired,
    onKeyPress: func,
    onFocus: func,
    onBlur: func,
    placeholder: string,
    autoFocus: bool,
    autoComplete: string,
    name: string,
    type: oneOf(['text', 'email', 'password', 'search', 'url', 'tel']),
    isTextArea: bool,
    isDisabled: bool,
    shouldSpellcheck: bool,
    shouldValidate: bool,
    shouldPreventAllSpaces: bool, // note that this won't work when `type` is set to 'email' see https://github.com/facebook/react/issues/6368
    icon: string,
    rows: number,
    inputStyle: oneOf(['primary', 'secondary', 'tertiary']),
    maxLength: number,
    height: number,
    hasError: bool,
    isReadOnly: bool,
    useFloatingPlaceholder: bool,
    testId: string,
};

TextInput.defaultProps = {
    autoFocus: false,
    type: 'text',
    isTextArea: false,
    isDisabled: false,
    shouldSpellcheck: true,
    shouldValidate: false,
    shouldPreventAllSpaces: false,
    inputStyle: inputStyles.primary,
    hasError: false,
    isReadOnly: false,
    useFloatingPlaceholder: false,
    onChange: () => {},
};

export default TextInput;
