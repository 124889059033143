import * as React from 'react';

const SvgHint = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.757 3.757A6 6 0 0 1 18 8a8.25 8.25 0 0 1-1.06 3.9c-.313.55-.587 1.121-.82 1.71l-.8 2.13a1.91 1.91 0 0 1-.64.83c.207.266.32.593.32.93a1.5 1.5 0 0 1-.38 1A1.5 1.5 0 0 1 13.5 21h-1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1 0-1h-1a1.5 1.5 0 0 1-1.12-2.5 1.5 1.5 0 0 1-.38-1c0-.337.113-.664.32-.93a1.91 1.91 0 0 1-.64-.83l-.8-2.13a13.418 13.418 0 0 0-.82-1.71A8.25 8.25 0 0 1 6 8a6 6 0 0 1 1.757-4.243Zm2.39 16.097A.5.5 0 0 0 10.5 20h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.354.854Zm3.707-2A.5.5 0 0 0 13.5 17h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .354-.146Zm1.326-4.594c.255-.647.556-1.275.9-1.88A7.33 7.33 0 0 0 17 8 5 5 0 0 0 7 8a7.47 7.47 0 0 0 .91 3.38c.351.602.655 1.23.91 1.88l.8 2.13a1.06 1.06 0 0 0 .88.61h3a1.06 1.06 0 0 0 .88-.61l.8-2.13Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgHint;
