import React from 'react';

const SvgParagraphStyle = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="m2.53 19.65 1.34.56v-9.03l-2.43 5.86c-.41 1.02.08 2.19 1.09 2.61Zm19.5-3.7L17.07 3.98a2.013 2.013 0 0 0-1.81-1.23c-.26 0-.53.04-.79.15L7.1 5.95a1.999 1.999 0 0 0-1.08 2.6l4.96 11.97a1.997 1.997 0 0 0 2.6 1.08l7.36-3.05a1.994 1.994 0 0 0 1.09-2.6Zm-9.2 3.8L7.87 7.79l7.36-3.04 4.95 11.95-7.35 3.05Z" />
        <path d="M11 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM5.88 19.75c0 1.1.9 2 2 2h1.45l-3.45-8.34v6.34Z" />
    </svg>
);
export default SvgParagraphStyle;
