import * as React from 'react';

const SvgGallery = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.23 3h11.54a.5.5 0 1 0 0-1H6.23a.5.5 0 0 0 0 1ZM19.08 5H4.92a.5.5 0 0 1 0-1h14.16a.5.5 0 1 1 0 1Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.125 14.8a1.57 1.57 0 1 0 1.73-2.62 1.57 1.57 0 0 0-1.73 2.62Zm.475-1.707a.57.57 0 0 1 .4-.163.56.56 0 0 1 .56.56.57.57 0 0 1-.56.57.58.58 0 0 1-.57-.57.57.57 0 0 1 .17-.397Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 18.11h6.17a.459.459 0 0 0 .095 0H17a.5.5 0 0 0 .5-.5v-7.28a.5.5 0 0 0-.5-.5H7a.5.5 0 0 0-.5.5v4.915a.501.501 0 0 0 0 .48v1.885a.5.5 0 0 0 .5.5Zm.44-1h4.61l-2.63-2.95A.491.491 0 0 0 9 14a.48.48 0 0 0-.37.19l-1.183 1.465-.007 1.455Zm2.71-3.61 3.22 3.61h3.13v-6.28H7.47l-.015 3.22.395-.49A1.549 1.549 0 0 1 9 13a1.47 1.47 0 0 1 1.15.5Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 22h14a1.5 1.5 0 0 0 1.5-1.5v-13A1.5 1.5 0 0 0 19 6H5a1.5 1.5 0 0 0-1.5 1.5v13A1.5 1.5 0 0 0 5 22ZM4.646 7.146A.5.5 0 0 1 5 7h14a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .146-.354Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgGallery;
