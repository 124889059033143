import accessibility from './accessibility';
import activity from './activity';
import add from './add';
import addCircle from './addCircle';
import addComment from './addComment';
import addFolder from './addFolder';
import addPage from './addPage';
import addTag from './addTag';
import additionalOptions from './additionalOptions';
import alignCenter from './alignCenter';
import alignJustify from './alignJustify';
import alignLeft1 from './alignLeft1';
import alignLeft from './alignLeft';
import alignRight from './alignRight';
import alignTop from './alignTop';
import alignVerticalBottom from './alignVerticalBottom';
import alignVerticalCenter from './alignVerticalCenter';
import animation from './animation';
import arrowGroup from './arrowGroup';
import assets from './assets';
import audio from './audio';
import avatar from './avatar';
import background from './background';
import bold from './bold';
import branching from './branching';
import caretArrowDown from './caretArrowDown';
import caretArrowLeft from './caretArrowLeft';
import caretArrowRight from './caretArrowRight';
import caretArrowUp from './caretArrowUp';
import check from './check';
import checkbox from './checkbox';
import checkboxChecked from './checkboxChecked';
import checkboxMinus from './checkboxMinus';
import clapboard from './clapboard';
import click from './click';
import cloud from './cloud';
import cloudSyncOff from './cloudSyncOff';
import cloudSyncOn from './cloudSyncOn';
import cloudSyncPaused from './cloudSyncPaused';
import colors from './colors';
import comments from './comments';
import config from './config';
import contactSupport from './contactSupport';
import content from './content';
import copy from './copy';
import cornersPill from './cornersPill';
import cornersRounded from './cornersRounded';
import cornersSquare from './cornersSquare';
import courses from './courses';
import crop from './crop';
import cross from './cross';
import deleteIcon from './delete';
import desktop from './desktop';
import details from './details';
import devices from './devices';
import dragHandle from './dragHandle';
import edit from './edit';
import embed from './embed';
import evolveCommunity from './evolveCommunity';
import expandOff from './expandOff';
import expandOn from './expandOn';
import exportIcon from './export';
import extensions from './extensions';
import file from './file';
import fill from './fill';
import filter from './filter';
import focus from './focus';
import folder from './folder';
import folderFilled from './folderFilled';
import folderMove from './folderMove';
import folderZip from './folderZip';
import fontSize from './fontSize';
import formatClear from './formatClear';
import fullSize from './fullSize';
import globalSettings from './globalSettings';
import grid from './grid';
import gridView from './gridView';
import help from './help';
import icon from './icon';
import indentDecrease from './indentDecrease';
import indentIncrease from './indentIncrease';
import insertHorizontalLine from './insertHorizontalLine';
import insertIcon from './insertIcon';
import insertLoremIpsum from './insertLoremIpsum';
import insertSpecialCharacter from './insertSpecialCharacter';
import insertTable from './insertTable';
import inspect from './inspect';
import italic from './italic';
import language from './language';
import lineHeight from './lineHeight';
import link from './link';
import listUnordered from './listUnordered';
import listView from './listView';
import lock from './lock';
import logic from './logic';
import logout from './logout';
import move from './move';
import moveTo from './moveTo';
import notifications from './notifications';
import orderedList from './orderedList';
import page from './page';
import paragraphStyle from './paragraphStyle';
import password from './password';
import paste from './paste';
import play from './play';
import premium from './premium';
import preview from './preview';
import profile from './profile';
import publish from './publish';
import pushPin from './pushPin';
import pushPinFilled from './pushPinFilled';
import radioButton from './radioButton';
import radioButtonSelected from './radioButtonSelected';
import redo from './redo';
import remove from './remove';
import removeCircle from './removeCircle';
import resetZoom from './resetZoom';
import review from './review';
import search from './search';
import selectAll from './selectAll';
import settings from './settings';
import shadow from './shadow';
import sideBottom from './sideBottom';
import sideLeft from './sideLeft';
import sideRight from './sideRight';
import sideTop from './sideTop';
import smartphone from './smartphone';
import smartphoneLandscape from './smartphoneLandscape';
import snap from './snap';
import sortAlpha from './sortAlpha';
import standardArrowDown from './standardArrowDown';
import standardArrowLeft from './standardArrowLeft';
import standardArrowRight from './standardArrowRight';
import standardArrowUp from './standardArrowUp';
import status from './status';
import stop from './stop';
import strikethrough from './strikethrough';
import strokeDashed from './strokeDashed';
import strokeDotted from './strokeDotted';
import subscript from './subscript';
import superscript from './superscript';
import supportTickets from './supportTickets';
import swap from './swap';
import syncOff from './syncOff';
import syncOn from './syncOn';
import tablet from './tablet';
import tabletLandscape from './tabletLandscape';
import tag from './tag';
import team from './team';
import templates from './templates';
import theme from './theme';
import titleDecoration from './titleDecoration';
import tooltip from './tooltip';
import tracking from './tracking';
import triangleArrowDown from './triangleArrowDown';
import triangleArrowLeft from './triangleArrowLeft';
import triangleArrowRight from './triangleArrowRight';
import triangleArrowUp from './triangleArrowUp';
import underline from './underline';
import undo from './undo';
import updates from './updates';
import users from './users';
import versions from './versions';
import video from './video';
import visibilityOff from './visibilityOff';
import visibilityOn from './visibilityOn';
import warning from './warning';
import widthFull from './widthFull';
import widthHalf from './widthHalf';
import widthTwothirdsLeft from './widthTwothirdsLeft';
import widthTwothirdsRight from './widthTwothirdsRight';
import zoomIn from './zoomIn';
import zoomOut from './zoomOut';

export default {
    accessibility,
    activity,
    add,
    addCircle,
    addComment,
    addFolder,
    addPage,
    addTag,
    additionalOptions,
    alignCenter,
    alignJustify,
    alignLeft1,
    alignLeft,
    alignRight,
    alignTop,
    alignVerticalBottom,
    alignVerticalCenter,
    animation,
    arrowGroup,
    assets,
    audio,
    avatar,
    background,
    bold,
    branching,
    caretArrowDown,
    caretArrowLeft,
    caretArrowRight,
    caretArrowUp,
    check,
    checkbox,
    checkboxChecked,
    checkboxMinus,
    clapboard,
    click,
    cloud,
    cloudSyncOff,
    cloudSyncOn,
    cloudSyncPaused,
    colors,
    comments,
    config,
    contactSupport,
    content,
    copy,
    cornersPill,
    cornersRounded,
    cornersSquare,
    courses,
    crop,
    cross,
    delete: deleteIcon,
    desktop,
    details,
    devices,
    dragHandle,
    edit,
    embed,
    evolveCommunity,
    expandOff,
    expandOn,
    export: exportIcon,
    extensions,
    file,
    fill,
    filter,
    focus,
    folder,
    folderFilled,
    folderMove,
    folderZip,
    fontSize,
    formatClear,
    fullSize,
    globalSettings,
    grid,
    gridView,
    help,
    icon,
    indentDecrease,
    indentIncrease,
    insertHorizontalLine,
    insertIcon,
    insertLoremIpsum,
    insertSpecialCharacter,
    insertTable,
    inspect,
    italic,
    language,
    lineHeight,
    link,
    listUnordered,
    listView,
    lock,
    logic,
    logout,
    move,
    moveTo,
    notifications,
    orderedList,
    page,
    paragraphStyle,
    password,
    paste,
    play,
    premium,
    preview,
    profile,
    publish,
    pushPin,
    pushPinFilled,
    radioButton,
    radioButtonSelected,
    redo,
    remove,
    removeCircle,
    resetZoom,
    review,
    search,
    selectAll,
    settings,
    shadow,
    sideBottom,
    sideLeft,
    sideRight,
    sideTop,
    smartphone,
    smartphoneLandscape,
    snap,
    sortAlpha,
    standardArrowDown,
    standardArrowLeft,
    standardArrowRight,
    standardArrowUp,
    status,
    stop,
    strikethrough,
    strokeDashed,
    strokeDotted,
    subscript,
    superscript,
    supportTickets,
    swap,
    syncOff,
    syncOn,
    tablet,
    tabletLandscape,
    tag,
    team,
    templates,
    theme,
    titleDecoration,
    tooltip,
    tracking,
    triangleArrowDown,
    triangleArrowLeft,
    triangleArrowRight,
    triangleArrowUp,
    underline,
    undo,
    updates,
    users,
    versions,
    video,
    visibilityOff,
    visibilityOn,
    warning,
    widthFull,
    widthHalf,
    widthTwothirdsLeft,
    widthTwothirdsRight,
    zoomIn,
    zoomOut,
};
