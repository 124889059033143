import * as React from 'react';

const SvgChecklist = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.33 10.45A.49.49 0 0 1 4 10.3L2.18 8.51a.5.5 0 0 1 0-.71.48.48 0 0 1 .7 0l1.45 1.44 4.39-4.39a.5.5 0 0 1 .853.35.5.5 0 0 1-.143.35L4.68 10.3a.47.47 0 0 1-.35.15ZM4 19.15a.49.49 0 0 0 .33.15.469.469 0 0 0 .35-.15l4.75-4.75a.5.5 0 0 0-.355-.848.502.502 0 0 0-.355.148l-4.39 4.39-1.45-1.44a.5.5 0 0 0-.848.355.5.5 0 0 0 .148.355L4 19.15Zm6.88-1.03h10.59a.5.5 0 0 0 0-1H10.88a.5.5 0 0 0 0 1Zm0-8.85h10.59a.5.5 0 0 0 0-1H10.88a.5.5 0 1 0 0 1Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgChecklist;
