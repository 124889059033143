import * as React from 'react';

const SvgActionButton = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19 22.09a1.56 1.56 0 0 1-1.26-.65L16 19l-1.27 2a1 1 0 0 1-1.85-.3L10.09 8.6a1 1 0 0 1 1.55-1.08L22 14.34a1 1 0 0 1-.36 1.85l-2.37.47L21 19.15a1.52 1.52 0 0 1-.38 2.13l-.77.54a1.5 1.5 0 0 1-.85.27Zm-3-4.55a.49.49 0 0 1 .41.22l2.16 3.11a.54.54 0 0 0 .75.13l.77-.54a.49.49 0 0 0 .22-.34.48.48 0 0 0-.09-.4L18 16.61a.52.52 0 0 1-.05-.47.51.51 0 0 1 .36-.31l3.13-.62-10.35-6.86 2.76 12.13 1.7-2.7a.54.54 0 0 1 .42-.24H16Z"
            fill="#393B3D"
        />
        <path
            d="M10 19.1H3.63a1.47 1.47 0 0 1-1.47-1.47V4.39a1.47 1.47 0 0 1 1.47-1.47h16.76a1.46 1.46 0 0 1 1.46 1.47V11a.5.5 0 0 1-1 0V4.39a.46.46 0 0 0-.46-.47H3.63a.47.47 0 0 0-.47.47v13.24a.47.47 0 0 0 .47.47H10a.5.5 0 1 1 0 1Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgActionButton;
