import React from 'react';

const SvgInspect = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4c2.21 0 4-1.79 4-4s-1.79-4-4-4zM20.94 11c-0.46-4.17-3.77-7.48-7.94-7.94v-2.060h-2v2.060c-4.17 0.46-7.48 3.77-7.94 7.94h-2.060v2h2.060c0.46 4.17 3.77 7.48 7.94 7.94v2.060h2v-2.060c4.17-0.46 7.48-3.77 7.94-7.94h2.060v-2h-2.060zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7c3.87 0 7 3.13 7 7s-3.13 7-7 7z" />
    </svg>
);
export default SvgInspect;
