import React from 'react';

const SvgAddTag = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="m21 12-4.37 6.16c-.37.52-.98.84-1.63.84h-3v-2h3l3.55-5L15 6.998H5v3H3v-3c0-1.1.9-2 2-2h10c.65 0 1.26.31 1.63.84l4.37 6.16V12Zm-11 3H7v-3H5v3H2v2h3v3h2v-3h3v-2Z" />
    </svg>
);
export default SvgAddTag;
