import React from 'react';

const SvgVideo = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M15 8v8H5V8h10Zm1-2H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 3.999v-11l-4 4v-3.5c0-.55-.45-1-1-1V6Z" />
    </svg>
);
export default SvgVideo;
