import React from 'react';

const SvgPushPinFilled = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M16 9V4h1c.55 0 1-.45 1-1s-.45-1-1-1H7c-.55 0-1 .45-1 1s.45 1 1 1h1v5c0 1.66-1.34 3-3 3v2h5.97v7l1 1 .999-1v-7h6.03v-2c-1.66 0-3-1.34-3-3Z" />
    </svg>
);
export default SvgPushPinFilled;
