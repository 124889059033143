import React from 'react';
import { Typography } from 'uikit/typography';
import AuthFormOAuthProviderButton from './authFormOAuthProviderButton';

const PROVIDERS = {
    google: {
        name: 'google',
        text: 'Sign in with Google',
        logo: 'sso-google-logo.png',
        endpoint: '/api/oauth/google',
    },
    facebook: {
        name: 'facebook',
        text: 'Continue with Facebook',
        logo: 'sso-facebook-logo.png',
        endpoint: '/api/oauth/facebook',
    },
};

function AuthFormOAuthProviders() {
    if (!window.authentication?.hasOAuthLogin) return null;

    const providers = _.reduce(
        window.authentication.oAuthProviders,
        (accum, enabled, name) => {
            if (enabled) {
                accum.push(<AuthFormOAuthProviderButton key={name} {...PROVIDERS[name]} />);
            }
            return accum;
        },
        []
    );

    if (!providers.length) return null;

    return (
        <div className="authentication-oauth-providers">
            <div className="authentication-oauth-providers-divider">
                <Typography component="span" fontStyle="micro" fontWeight="bold">
                    OR
                </Typography>
            </div>
            {providers}
        </div>
    );
}

export default AuthFormOAuthProviders;
