import React from 'react';

const SvgCornersPill = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M15 8a4 4 0 0 1 0 8H9a4 4 0 1 1 0-8h6ZM9 6a6 6 0 0 0 0 12h6a6 6 0 0 0 0-12H9Z" />
    </svg>
);
export default SvgCornersPill;
