import React from 'react';

const SvgDragHandle = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M20 9H4v2h16V9ZM4 15h16v-2H4v2Z" />
    </svg>
);
export default SvgDragHandle;
