import * as React from 'react';

const SvgIFrame = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.49 14.36a.54.54 0 0 0 .35.14.54.54 0 0 0 .36-.14.51.51 0 0 0 0-.71l-2.27-2.27L8.2 9.11a.51.51 0 0 0 0-.71.5.5 0 0 0-.71 0l-2.62 2.62a.5.5 0 0 0 0 .71l2.62 2.63ZM16.16 14.5a.54.54 0 0 1-.36-.14.51.51 0 0 1 0-.71l2.27-2.27-2.27-2.27a.51.51 0 0 1 0-.71.5.5 0 0 1 .71 0l2.62 2.62a.5.5 0 0 1 0 .71l-2.62 2.63a.54.54 0 0 1-.35.14ZM10.43 14.45a.64.64 0 0 0 .23.05.51.51 0 0 0 .45-.27l2.68-5.25a.51.51 0 0 0-.9-.46l-2.68 5.25a.52.52 0 0 0 .22.68Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.58 18.15h7.92v2.25H7.89a.51.51 0 0 0-.5.5.5.5 0 0 0 .5.5h8.22a.5.5 0 0 0 .5-.5.51.51 0 0 0-.5-.5H12.5v-2.25h7.92a1.5 1.5 0 0 0 1.5-1.5V6.11a1.5 1.5 0 0 0-1.5-1.5H3.58a1.5 1.5 0 0 0-1.5 1.5v10.54a1.5 1.5 0 0 0 1.5 1.5Zm0-1h16.84a.5.5 0 0 0 .5-.5V6.11a.5.5 0 0 0-.5-.5H3.58a.5.5 0 0 0-.5.5v10.54a.5.5 0 0 0 .5.5Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgIFrame;
