import * as React from 'react';

const SvgSurvey = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M21.11 13.21a3.47 3.47 0 0 0 .69-2.16 2.55 2.55 0 0 0-.33-1.35 1.46 1.46 0 0 0-1.28-.7h-2.61c1.42-2.59 1.78-4.54 1.07-5.81A2.4 2.4 0 0 0 16.6 2a.49.49 0 0 0-.49.43 7.91 7.91 0 0 1-2.77 4.32 15.49 15.49 0 0 1-5.29 3.11A1.5 1.5 0 0 0 6.7 9h-3a1.5 1.5 0 0 0-1.5 1.5v9A1.5 1.5 0 0 0 3.7 21h3a1.51 1.51 0 0 0 1.39-.95 8.17 8.17 0 0 1 3.41 1c.9.48 1.74.93 4.51.93a3.48 3.48 0 0 0 1.91-.53 2 2 0 0 0 .92-1.34 2.12 2.12 0 0 0 1-1.09 4.46 4.46 0 0 0 .43-2.04 2.19 2.19 0 0 0 .66-.85 4.36 4.36 0 0 0 .44-1.83 2.09 2.09 0 0 0-.24-1.05v-.06l-.02.02ZM6.69 20h-3a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5Zm13.84-7.73c-.15.31-.3.42-.37.42a.5.5 0 0 0-.5.5.51.51 0 0 0 .5.5s.07 0 .12.08c.078.172.112.361.1.55a3.36 3.36 0 0 1-.32 1.39c-.2.39-.4.54-.49.54a.5.5 0 0 0-.27.92 3.25 3.25 0 0 1-.5 1.72c-.15.21-.3.33-.42.33a.5.5 0 0 0-.5.5c0 .88-1 1.28-1.88 1.28-2.51 0-3.21-.38-4-.81a9.12 9.12 0 0 0-3.78-1.14v-8.18A16.52 16.52 0 0 0 14 7.49a9.33 9.33 0 0 0 3-4.42c.326.096.602.314.77.61.34.6.59 2.1-1.49 5.56a.52.52 0 0 0 0 .51.49.49 0 0 0 .43.25h3.48a.46.46 0 0 1 .43.23c.133.252.195.535.18.82 0 .422-.091.838-.27 1.22Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgSurvey;
