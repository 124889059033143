import * as React from 'react';

const SvgTextInput = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.62 10.3h.67a.5.5 0 0 0 0-1H5a.5.5 0 1 0 0 1h.62v3.27H5a.5.5 0 0 0 0 1h2.29a.51.51 0 0 0 .5-.5.5.5 0 0 0-.5-.5h-.67V10.3Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.52 17.31h17a1.5 1.5 0 0 0 1.48-1.5V8.19a1.5 1.5 0 0 0-1.5-1.5h-17A1.5 1.5 0 0 0 2 8.19v7.62a1.5 1.5 0 0 0 1.52 1.5Zm-.354-9.474a.5.5 0 0 1 .354-.146h17a.5.5 0 0 1 .5.5v7.62a.5.5 0 0 1-.5.5h-17a.5.5 0 0 1-.5-.5V8.19a.5.5 0 0 1 .146-.354Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgTextInput;
