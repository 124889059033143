import React from 'react';

const SvgSideBottom = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M15 6H9v6h6V6ZM7 4h10v10H7V4ZM3 18v2h18v-2H3Z" />
    </svg>
);
export default SvgSideBottom;
