import * as React from 'react';

const SvgSocialMCQ = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.667 8.247a1.5 1.5 0 1 0 1.666-2.494 1.5 1.5 0 0 0-1.666 2.494Zm.555-1.663a.5.5 0 1 1 .556.832.5.5 0 0 1-.556-.832Z"
            fill="#393B3D"
        />
        <path
            d="M21.5 7.5h-15a.5.5 0 1 1 0-1h15a.5.5 0 0 1 0 1ZM6.5 12.5h15a.5.5 0 0 0 0-1h-15a.5.5 0 0 0 0 1ZM6.5 17.5h15a.5.5 0 0 0 0-1h-15a.5.5 0 0 0 0 1Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.5 13.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm0-2a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1ZM2.667 18.247a1.5 1.5 0 1 0 1.666-2.495 1.5 1.5 0 0 0-1.666 2.495Zm.555-1.663a.5.5 0 1 1 .556.83.5.5 0 0 1-.556-.83Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgSocialMCQ;
