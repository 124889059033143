import * as React from 'react';

const SvgGame = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.28 12.13a1.45 1.45 0 1 1 0-2.9 1.45 1.45 0 0 1 0 2.9Zm0-1.9a.45.45 0 1 0 0 .9.45.45 0 0 0 0-.9ZM17.474 13.886a1.45 1.45 0 1 0 1.611-2.41 1.45 1.45 0 0 0-1.61 2.41Zm.556-1.58a.45.45 0 1 1 .5.747.45.45 0 0 1-.5-.747Z"
            fill="#393B3D"
        />
        <path
            d="M6.88 14.41a.5.5 0 0 1-.5-.5v-1.53H4.85a.5.5 0 0 1 0-1h1.53V9.84a.51.51 0 0 1 .5-.5.5.5 0 0 1 .5.5v1.54h1.54a.5.5 0 0 1 .5.5.51.51 0 0 1-.5.5H7.38v1.53a.5.5 0 0 1-.5.5Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.14 17a4.89 4.89 0 0 1-3.08-1.09h-4A4.93 4.93 0 0 1 7 17 5 5 0 0 1 7 7h10.14a5 5 0 1 1 0 10Zm-7.25-2.07h4.35a.49.49 0 0 1 .32.12 3.89 3.89 0 0 0 2.58 1 4 4 0 1 0 0-8H7a4 4 0 0 0 0 8 3.93 3.93 0 0 0 2.58-1 .47.47 0 0 1 .31-.12Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgGame;
