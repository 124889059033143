import React from 'react';

const SvgSideLeft = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M6 3v18H4V3h2Zm12 6h-6v6h6V9Zm-8-2h10v10H10V7Z" />
    </svg>
);
export default SvgSideLeft;
