import React from 'react';

const SvgSmartphoneLandscape = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M23 15.5v-8c0-1.38-1.12-2.5-2.5-2.5h-17c-1.38 0-2.5 1.12-2.5 2.5v8c0 1.38 1.12 2.5 2.5 2.5h17c1.38 0 2.5-1.12 2.5-2.5zM2 11.5c0-0.83 0.67-1.5 1.5-1.5s1.5 0.67 1.5 1.5c0 0.83-0.67 1.5-1.5 1.5s-1.5-0.67-1.5-1.5zM6 16v-9h14v9h-14z" />
    </svg>
);
export default SvgSmartphoneLandscape;
