import * as React from 'react';

const SvgInfographic = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14 6.11h4.66a.5.5 0 0 0 0-1H14a.5.5 0 0 0 0 1ZM18.67 8.34H14a.5.5 0 0 1 0-1h4.66a.5.5 0 0 1 .01 1ZM14 10.57h2.34a.5.5 0 0 0-.01-1H14a.5.5 0 0 0 0 1ZM9.74 14.73H5.07a.5.5 0 0 1-.5-.5.51.51 0 0 1 .5-.5h4.67a.51.51 0 0 1 .5.5.5.5 0 0 1-.5.5ZM5.07 17h4.67a.5.5 0 0 0 .5-.5.51.51 0 0 0-.5-.5H5.07a.51.51 0 0 0-.5.5.5.5 0 0 0 .5.5ZM7.41 19.19H5.07a.5.5 0 0 1-.5-.5.51.51 0 0 1 .5-.5h2.34a.51.51 0 0 1 .5.5.5.5 0 0 1-.5.5Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.097 10.348a3.42 3.42 0 0 0 5.311-2.74.5.5 0 0 0 0-.215A3.42 3.42 0 0 0 8.01 4.08h-.016a3.42 3.42 0 0 0-1.897 6.268Zm3.904-1.502c.173-.258.293-.546.357-.846H8a.5.5 0 0 1-.5-.5V5.13a2.42 2.42 0 1 0 2.502 3.716Zm-.3-3.05c.332.332.558.751.655 1.204H8.5V5.136c.452.1.87.326 1.202.66ZM12.31 19.38h6.36a.5.5 0 0 0 .5-.5V13.6a.5.5 0 0 0-.5-.5h-6.36a.5.5 0 0 0-.5.5v5.28a.5.5 0 0 0 .5.5Zm5.86-1h-5.36V14.1h5.36v4.28Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.43 22h17a1.5 1.5 0 0 0 1.5-1.5v-17a1.5 1.5 0 0 0-1.5-1.5h-17a1.5 1.5 0 0 0-1.5 1.5v17a1.5 1.5 0 0 0 1.5 1.5ZM3.076 3.146A.5.5 0 0 1 3.43 3h17a.5.5 0 0 1 .5.5v17a.5.5 0 0 1-.5.5h-17a.5.5 0 0 1-.5-.5v-17a.5.5 0 0 1 .146-.354Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgInfographic;
