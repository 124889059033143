import React from 'react';

const SvgFolderFilled = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M10 4h-6c-1.1 0-1.99 0.9-1.99 2l-0.010 12c0 1.1 0.9 2 2 2h16c1.1 0 2-0.9 2-2v-10c0-1.1-0.9-2-2-2h-8l-2-2z" />
    </svg>
);
export default SvgFolderFilled;
