import React from 'react';

const SvgPremium = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="m19.3 8.125-1.075-2.5-2.6-1.15 2.6-1.125L19.3.975l1.075 2.375 2.6 1.125-2.6 1.15-1.075 2.5ZM19.3 23l-1.075-2.4-2.6-1.125 2.6-1.125 1.075-2.525 1.075 2.525 2.6 1.125-2.6 1.125L19.3 23ZM8.325 19.15l-2.3-4.925L1 11.975l5.025-2.25 2.3-4.9 2.325 4.9 5 2.25-5 2.25-2.325 4.925Zm0-3.7 1.2-2.4 2.45-1.075-2.45-1.075-1.2-2.4-1.175 2.4-2.475 1.075L7.15 13.05l1.175 2.4Z" />
    </svg>
);
export default SvgPremium;
