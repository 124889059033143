import React from 'react';

const SvgStrikethrough = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M10 19h4v-3h-4v3ZM5 4v3h5v3h4V7h5V4H5ZM3 14h18v-2H3v2Z" />
    </svg>
);
export default SvgStrikethrough;
