import React from 'react';

const SvgPublish = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M5 4h14v2H5V4Zm0 10h4v6h6v-6h4l-7-7-7 7Zm8-2v6h-2v-6H9.83L12 9.83 14.168 12h-1.17Z" />
    </svg>
);
export default SvgPublish;
