import React from 'react';

const SvgStandardArrowLeft = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8.001 1.41-1.41-5.58-5.59H20v-2Z" />
    </svg>
);
export default SvgStandardArrowLeft;
