import axios from 'axios';

export const login = async credentials => {
    await axios.post('api/authentication/login', credentials);
};

export const forgotPassword = async email => {
    await axios.post('api/authentication/forgotPassword', { email });
};

export const register = async (data, token) => {
    await axios.post(`/api/authentication/register/${token}`, data);
};

export const resetPassword = async (data, token) => {
    await axios.post(`/api/authentication/resetPassword/${token}`, data);
};

export const getAuthenticatedUser = () => axios.get('api/isAuthenticated');

export const logout = () => axios.post('api/authentication/logout');

export const changePassword = data => axios.post('api/changePassword', data);

export const loginAsUser = userId => axios.post(`api/users/${userId}/login`);
