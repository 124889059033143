import React from 'react';

const SvgAlignTop = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M22 2v2H2V2h20ZM7 22h3V6H7v16Zm7-6h3V6h-3v10Z" />
    </svg>
);
export default SvgAlignTop;
