import React from 'react';

const SvgTitleDecoration = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#title-decoration_svg__a)">
            <path d="M0 19.429h24V24H0v-4.571Zm4.188-3.43h2.904l1.524-4.09h6.78l1.512 4.09h2.904L13.5 0h-3L4.188 16Zm5.304-6.41 2.436-6.618h.144l2.436 6.618H9.492Z" />
        </g>
        <defs>
            <clipPath id="title-decoration_svg__a">
                <path d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgTitleDecoration;
