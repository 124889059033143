import React from 'react';
import { bool, func, string } from 'prop-types';
import { Button } from 'uikit/button';

const AuthFormSubmit = ({ testId, text, canSubmit, onSubmit }) => (
    <Button
        className="authentication-form-submit"
        type="primary"
        testId={testId}
        text={text}
        isFullWidth
        isDisabled={!canSubmit}
        onClick={onSubmit}
    />
);

AuthFormSubmit.propTypes = {
    testId: string,
    text: string,
    canSubmit: bool.isRequired,
    onSubmit: func.isRequired,
};

AuthFormSubmit.defaultProps = {
    text: 'Submit',
};

export default AuthFormSubmit;
