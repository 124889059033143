import React from 'react';

const SvgBranching = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3h7ZM7 9H4V5h3v4Zm10 6h3v4h-3v-4Zm0-10h3v4h-3V5Z" />
    </svg>
);
export default SvgBranching;
