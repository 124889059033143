import React from 'react';
import { bool, func, string } from 'prop-types';
import { TextInput } from 'uikit/textInput';
import AuthFormError from './authFormError';

const AuthFormTextInput = props => (
    <div className="authentication-form-field">
        <TextInput
            type={props.type}
            key={props.name}
            name={props.name}
            testId={`${props.name}-text-input`}
            value={props.value}
            placeholder={props.placeholder}
            autoComplete={props.autoComplete}
            shouldValidate={props.shouldValidate}
            onKeyPress={e => (e.key === 'Enter' ? props.onEnterPressed() : false)}
            onChange={props.onChange}
            inputStyle="secondary"
            useFloatingPlaceholder
            shouldPreventAllSpaces={props.shouldPreventAllSpaces}
            shouldSpellcheck={false}
        />
        <AuthFormError error={props.error} textAlign="left" />
    </div>
);

AuthFormTextInput.propTypes = {
    name: string.isRequired,
    value: string.isRequired,
    type: string.isRequired,
    placeholder: string.isRequired,
    autoComplete: string,
    shouldValidate: bool,
    shouldPreventAllSpaces: bool,
    onEnterPressed: func.isRequired,
    onChange: func.isRequired,
    error: string,
};

AuthFormTextInput.defaultProps = {
    onEnterPressed: () => {},
};

export default AuthFormTextInput;
