import * as React from 'react';

const SvgGif = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.29 9.86a3.31 3.31 0 0 0-2.12-.81 3.12 3.12 0 0 0 0 6.23 4.39 4.39 0 0 0 2.15-.56.16.16 0 0 0 .07-.14v-2.22a.16.16 0 0 0-.16-.16H8.57a.16.16 0 0 0-.16.16v.69a.16.16 0 0 0 .16.15h.64v.75a2.68 2.68 0 0 1-1 .21 2 2 0 1 1 0-4 2 2 0 0 1 1.31.5.15.15 0 0 0 .23 0l.54-.56a.17.17 0 0 0 0-.24ZM12.24 9.19a.17.17 0 0 0-.05.11V15a.17.17 0 0 0 .16.16h.81a.17.17 0 0 0 .16-.16V9.3a.17.17 0 0 0-.16-.16h-.81a.17.17 0 0 0-.11.05ZM15.29 9.3a.17.17 0 0 1 .17-.16H19a.17.17 0 0 1 .17.16v.7a.17.17 0 0 1-.17.17h-2.58v1.63h2.14a.16.16 0 0 1 .16.16v.71a.17.17 0 0 1-.16.17h-2.14V15a.17.17 0 0 1-.17.16h-.79a.17.17 0 0 1-.17-.16V9.3Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.41 20h17.18a1.5 1.5 0 0 0 1.5-1.5v-13a1.5 1.5 0 0 0-1.5-1.5H3.41a1.5 1.5 0 0 0-1.5 1.5v13a1.5 1.5 0 0 0 1.5 1.5ZM3.056 5.146A.5.5 0 0 1 3.41 5h17.18a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-.5.5H3.41a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .146-.354Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgGif;
