import React from 'react';

const SvgAudio = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="m12 3 .01 10.55a4 4 0 0 0-2-.55C7.79 13 6 14.79 6 17s1.79 4 4.01 4c2.22 0 3.99-1.79 3.99-4V7h4V3h-6Zm-1.99 16c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z" />
    </svg>
);
export default SvgAudio;
