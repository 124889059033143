import React from 'react';
import { usePublicPath } from '../../../publicPath';

const AuthFormIntellumLogo = () => (
    <div className="authentication-form-logo authentication-form-intellum-logo">
        <img
            className="authentication-form-intellum-logo-image"
            src={`${usePublicPath()}/assets/intellum.png`}
            alt="Intellum"
        />
    </div>
);

export default AuthFormIntellumLogo;
