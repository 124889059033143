import * as React from 'react';

const SvgAccordion = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M20.56 11.51H3.44A1.5 1.5 0 0 1 1.94 10V4.94a1.5 1.5 0 0 1 1.5-1.5h17.12a1.5 1.5 0 0 1 1.5 1.5V10a1.5 1.5 0 0 1-1.5 1.51ZM3.44 4.44a.5.5 0 0 0-.5.5V10a.5.5 0 0 0 .5.5h17.12a.5.5 0 0 0 .5-.5V4.94a.5.5 0 0 0-.5-.5H3.44Z"
            fill="#393B3D"
        />
        <path
            d="M18.05 9.27a.5.5 0 0 1-.5-.5v-2.6a.51.51 0 0 1 .5-.5.5.5 0 0 1 .5.5v2.6a.5.5 0 0 1-.5.5Z"
            fill="#393B3D"
        />
        <path
            d="M19.35 8h-2.59a.5.5 0 1 1 0-1h2.59a.5.5 0 0 1 0 1ZM20.56 20.56H3.44a1.5 1.5 0 0 1-1.5-1.5V14a1.5 1.5 0 0 1 1.5-1.5h17.12a1.5 1.5 0 0 1 1.5 1.5v5.07a1.5 1.5 0 0 1-1.5 1.49ZM3.44 13.49a.5.5 0 0 0-.5.5v5.07a.5.5 0 0 0 .5.5h17.12a.5.5 0 0 0 .5-.5V14a.5.5 0 0 0-.5-.5l-17.12-.01Z"
            fill="#393B3D"
        />
        <path
            d="M18.05 18.33a.51.51 0 0 1-.5-.5v-2.6a.5.5 0 0 1 1 0v2.6a.5.5 0 0 1-.5.5Z"
            fill="#393B3D"
        />
        <path d="M19.35 17h-2.59a.5.5 0 0 1 0-1h2.59a.5.5 0 0 1 0 1Z" fill="#393B3D" />
    </svg>
);
export default SvgAccordion;
