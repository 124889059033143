const REDIRECT_SEARCH = '?redirectTo=';

function getRedirectURL(fullyQualified) {
    let url = fullyQualified ? window.appURL : '';
    const { href } = window.location;

    if (href.indexOf(REDIRECT_SEARCH) !== -1) {
        let [, redirectTo] = href.split(REDIRECT_SEARCH);
        if (redirectTo.charAt(0) !== '/') {
            redirectTo = `/${redirectTo}`;
        }
        if (redirectTo && redirectTo !== '/') {
            url += redirectTo;
        }
    }
    return url;
}

export default getRedirectURL;
