import React from 'react';

const SvgWidthFull = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2ZM4 18V6h1v12H4Zm3 0V6h10v12H7Zm13 0h-1V6h1v12Z" />
    </svg>
);
export default SvgWidthFull;
