import React from 'react';

const SvgTriangleArrowDown = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="m7 10 5 5 5-5H7Z" />
    </svg>
);
export default SvgTriangleArrowDown;
