import * as React from 'react';

const SvgTextReveal = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12 14.18a.5.5 0 0 1-.5-.5V12.5h-1.18a.5.5 0 0 1 0-1h1.18v-1.18a.5.5 0 0 1 1 0v1.18h1.18a.5.5 0 0 1 0 1H12.5v1.18a.5.5 0 0 1-.5.5Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.467 15.791A4.56 4.56 0 0 0 12 16.56 4.57 4.57 0 0 0 16.56 12a4.56 4.56 0 1 0-7.093 3.791Zm.555-6.751A3.56 3.56 0 0 1 12 8.44 3.57 3.57 0 0 1 15.56 12a3.56 3.56 0 1 1-5.538-2.96Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.74 21.84h12.52a1.5 1.5 0 0 0 1.5-1.5V3.66a1.5 1.5 0 0 0-1.5-1.5H5.74a1.5 1.5 0 0 0-1.5 1.5v16.68a1.5 1.5 0 0 0 1.5 1.5ZM5.386 3.306a.5.5 0 0 1 .354-.146h12.52a.5.5 0 0 1 .5.5v16.68a.5.5 0 0 1-.5.5H5.74a.5.5 0 0 1-.5-.5V3.66a.5.5 0 0 1 .146-.354Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgTextReveal;
