import React, { useState, useLayoutEffect } from 'react';
import { array, bool, func, number, oneOf, oneOfType, string } from 'prop-types';
import classnames from 'classnames';
import clickOutside from 'react-onclickoutside';
import ButtonTooltip from './buttonTooltip';

let ButtonGroupPopout = function({ buttonsRenderer, handleClickOutside }) {
    useLayoutEffect(() => {
        function onKeyDown(event) {
            if (event.key === 'Escape' || event.code === 'Escape') {
                handleClickOutside();
            }
        }
        window.addEventListener('keydown', onKeyDown);
        return () => window.removeEventListener('keydown', onKeyDown);
    }, [handleClickOutside]);

    ButtonGroupPopout.handleClickOutside = () => {
        handleClickOutside();
    };

    return buttonsRenderer();
};

const clickOutsideConfig = {
    handleClickOutside: () => ButtonGroupPopout.handleClickOutside,
};

ButtonGroupPopout = clickOutside(ButtonGroupPopout, clickOutsideConfig);

/**
 * @param {React.PropsWithChildren<{
 *  className?: string
 *  buttons: array
 *  isPopout: boolean
 *  value?: string | number | boolean
 *  size?: 'regular' | 'small' | 'medium' | 'large' | 'custom'
 *  popoutTooltip?: string
 *  tooltipPosition?: 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right' | 'left' | 'right'
 *  onChange: function
 * }>} props
 */
function ButtonGroup({
    className,
    buttons,
    isPopout,
    value,
    size,
    popoutTooltip,
    tooltipPosition,
    onChange,
}) {
    const [isPopoutOpen, setIsPopoutOpen] = useState(false);

    const onPopoutButtonClick = () => {
        setIsPopoutOpen(isPopoutOpen => {
            return (isPopoutOpen = !isPopoutOpen);
        });
    };

    const handleClickOutside = () => {
        setIsPopoutOpen(false);
    };

    const renderPopoutButton = () => {
        let selectedButton = _.find(buttons, { value });
        if (!selectedButton) [selectedButton] = buttons;
        return (
            <ButtonTooltip
                className="button-group-button is-popout-button"
                onClick={onPopoutButtonClick}
                size={size}
                icon={selectedButton.icon}
                tooltip={popoutTooltip}
                help={selectedButton.tooltip}
                tooltipPosition={tooltipPosition}
                isDisabled={isPopoutOpen}
            />
        );
    };

    const renderButtons = () => (
        <div className="button-group-buttons">
            {_.map(buttons, button => (
                <ButtonTooltip
                    key={button.value}
                    testId={button.testId}
                    className={classnames('button-group-button', {
                        'is-selected': button.value === value,
                    })}
                    onClick={() => {
                        setIsPopoutOpen(false);
                        onChange(button.value);
                    }}
                    size={size}
                    tooltipPosition={tooltipPosition}
                    {...button}
                />
            ))}
        </div>
    );

    return (
        <div
            className={classnames('button-group', className, {
                'is-popout': isPopout,
            })}
        >
            {isPopout && renderPopoutButton()}
            {isPopout && isPopoutOpen && (
                <ButtonGroupPopout
                    buttonsRenderer={renderButtons}
                    handleClickOutside={handleClickOutside}
                />
            )}
            {!isPopout && renderButtons()}
        </div>
    );
}

ButtonGroup.propTypes = {
    className: string,
    buttons: array.isRequired,
    isPopout: bool.isRequired,
    value: oneOfType([string, number, bool]),
    size: oneOf(['regular', 'small', 'medium', 'large', 'custom']),
    popoutTooltip: string,
    tooltipPosition: oneOf([
        'top',
        'top-left',
        'top-right',
        'bottom',
        'bottom-left',
        'bottom-right',
        'left',
        'right',
    ]),
    onChange: func.isRequired,
};

ButtonGroup.defaultProps = {
    isPopout: false,
};

export default ButtonGroup;
