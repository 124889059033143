import accordion from './Accordion';
import actionButton from './ActionButton';
import attachment from './Attachment';
import audio from './Audio';
import branching from './Branching';
import capture from './Capture';
import cardDrop from './CardDrop';
import checkboxGroup from './CheckboxGroup';
import checklist from './Checklist';
import code from './Code';
import confidenceChart from './ConfidenceChart';
import confidenceSlider from './ConfidenceSlider';
import courseAssessmentResults from './CourseAssessmentResults';
import dialog from './Dialog';
import discussion from './Discussion';
import dragAndDrop from './DragAndDrop';
import flipCard from './FlipCard';
import flowChart from './FlowChart';
import gallery from './Gallery';
import game from './Game';
import gif from './Gif';
import gmcq from './Gmcq';
import graphic from './Graphic';
import hint from './Hint';
import hotgraphic from './Hotgraphic';
import iFrame from './IFrame';
import icon from './Icon';
import imageSlider from './ImageSlider';
import infographic from './Infographic';
import interactiveVideo from './InteractiveVideo';
import linkedConfidenceSlider from './LinkedConfidenceSlider';
import linkedOpenInput from './LinkedOpenInput';
import links from './Links';
import matching from './Matching';
import mcq from './Mcq';
import media from './Media';
import mediaCarousel from './MediaCarousel';
import mediaGrid from './MediaGrid';
import narrative from './Narrative';
import numberInput from './NumberInput';
import openInput from './OpenInput';
import ordering from './Ordering';
import questionResult from './QuestionResult';
import radioGroup from './RadioGroup';
import select from './Select';
import simulation from './Simulation';
import singleFlipCard from './SingleFlipCard';
import slider from './Slider';
import sliderInput from './SliderInput';
import socialMCQ from './SocialMCQ';
import sorting from './Sorting';
import survey from './Survey';
import table from './Table';
import tabs from './Tabs';
import text from './Text';
import textAndGraphic from './TextAndGraphic';
import textArea from './TextArea';
import textInput from './TextInput';
import textNarrative from './TextNarrative';
import textReveal from './TextReveal';
import videoStream from './VideoStream';

export default {
    accordion,
    actionButton,
    attachment,
    audio,
    branching,
    capture,
    cardDrop,
    checkboxGroup,
    checklist,
    code,
    confidenceChart,
    confidenceSlider,
    courseAssessmentResults,
    dialog,
    discussion,
    dragAndDrop,
    flipCard,
    flowChart,
    gallery,
    game,
    gif,
    gmcq,
    graphic,
    hint,
    hotgraphic,
    iFrame,
    icon,
    imageSlider,
    infographic,
    interactiveVideo,
    linkedConfidenceSlider,
    linkedOpenInput,
    links,
    matching,
    mcq,
    media,
    mediaCarousel,
    mediaGrid,
    narrative,
    numberInput,
    openInput,
    ordering,
    questionResult,
    radioGroup,
    select,
    simulation,
    singleFlipCard,
    slider,
    sliderInput,
    socialMCQ,
    sorting,
    survey,
    table,
    tabs,
    text,
    textAndGraphic,
    textArea,
    textInput,
    textNarrative,
    textReveal,
    videoStream,
};
