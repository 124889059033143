import React from 'react';

const SvgAnimation = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M15 2c-2.71 0-5.05 1.54-6.22 3.78a7.058 7.058 0 0 0-3 3A7.013 7.013 0 0 0 2 15c0 3.87 3.13 7 7 7 2.71 0 5.05-1.54 6.22-3.78a7.057 7.057 0 0 0 3-3A7.013 7.013 0 0 0 22 9c0-3.87-3.13-7-7-7ZM9 20a5.002 5.002 0 0 1-4-8c0 3.87 3.13 7 7 7-.84.63-1.881 1-3 1Zm3-3a5.002 5.002 0 0 1-4-8c0 3.86 3.13 6.99 7 7-.84.63-1.881 1-3 1Zm4.7-3.3c-.53.19-1.1.3-1.7.3-2.76 0-5-2.24-5-5 0-.6.11-1.17.3-1.7.53-.19 1.1-.3 1.7-.3 2.76 0 5 2.24 5 5 0 .6-.11 1.17-.3 1.7ZM19 12c0-3.86-3.13-6.99-7-7a5.002 5.002 0 0 1 7 7Z" />
    </svg>
);
export default SvgAnimation;
