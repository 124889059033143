import * as React from 'react';

const SvgTextAndGraphic = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.455 8.607a1.65 1.65 0 1 0 1.79-2.773 1.65 1.65 0 0 0-1.79 2.773Zm.564-1.927a.65.65 0 0 1 .361-.11.66.66 0 0 1 .65.65.65.65 0 1 1-1.011-.54Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.23 15.88h-10a1.5 1.5 0 0 1-1.5-1.5v-10a1.5 1.5 0 0 1 1.5-1.5h10a1.5 1.5 0 0 1 1.5 1.5v10a1.5 1.5 0 0 1-1.5 1.5Zm-10-12a.51.51 0 0 0-.5.5v5.789L8.54 8.25a1.14 1.14 0 0 1 .82-.35 1.12 1.12 0 0 1 .81.35l6.404 6.63h.656a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5h-10Zm2.04 5.06-2.54 2.682v2.758a.51.51 0 0 0 .5.5h7.932L9.45 8.94a.12.12 0 0 0-.09 0 .12.12 0 0 0-.09 0Z"
            fill="#393B3D"
        />
        <path
            d="M21.18 18.71h-5.43a.51.51 0 0 1-.5-.5.5.5 0 0 1 .5-.5h5.43a.5.5 0 0 1 .5.5.51.51 0 0 1-.5.5ZM2.82 18.71h10.41a.5.5 0 0 0 0-1H2.82a.5.5 0 0 0-.5.5.51.51 0 0 0 .5.5ZM21.18 21.13H10.12a.5.5 0 0 1 0-1h11.06a.5.5 0 0 1 .5.5.51.51 0 0 1-.5.5ZM2.82 21.13H8a.51.51 0 0 0 .5-.5.5.5 0 0 0-.5-.5H2.82a.5.5 0 0 0-.5.5.51.51 0 0 0 .5.5Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgTextAndGraphic;
