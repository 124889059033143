import * as React from 'react';

const SvgFlipCard = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.08 18.22a.46.46 0 0 1-.19 0h-4.1a.5.5 0 0 1-.5-.5V6.44a.5.5 0 0 1 .5-.5h8.42a.5.5 0 0 1 .5.5v7.115a.503.503 0 0 1-.14.395l-4.13 4.13a.523.523 0 0 1-.36.14Zm3.63-5.12V6.94H8.29v10.28h3.29V13.6a.5.5 0 0 1 .5-.5h3.63Zm-.71 1h-2.42v2.42L15 14.1ZM17.85 15.08h2.8a.55.55 0 0 0 .35-.16l1.38-1.52a.5.5 0 0 0 .12-.33V8.74a.5.5 0 0 0-.5-.5h-4.15a.5.5 0 0 0-.5.5v5.84a.5.5 0 0 0 .5.5Zm2.58-1h-2.08V9.24h3.15v3.64l-1.07 1.2ZM4.8 15.08H2a.5.5 0 0 1-.5-.5V8.74a.5.5 0 0 1 .5-.5h4.15a.5.5 0 0 1 .5.5v4.33a.5.5 0 0 1-.12.33l-1.35 1.52a.53.53 0 0 1-.38.16Zm-2.3-1h2.08l1.07-1.2V9.24H2.5v4.84Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgFlipCard;
