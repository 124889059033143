import * as React from 'react';

const SvgTabs = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5.26 10.91h12.82a.5.5 0 0 0 0-1H5.26a.51.51 0 0 0-.5.5.5.5 0 0 0 .5.5ZM8.28 13.66h-3a.5.5 0 0 1-.5-.5.51.51 0 0 1 .5-.5h3a.5.5 0 0 1 0 1ZM10.66 13.66h7.42a.5.5 0 0 0 0-1h-7.42a.5.5 0 0 0 0 1ZM18.08 16.41h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 1 0 1ZM5.26 16.41h7.43a.5.5 0 0 0 .5-.5.51.51 0 0 0-.5-.5H5.26a.51.51 0 0 0-.5.5.5.5 0 0 0 .5.5Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.51 19.89h17a1.5 1.5 0 0 0 1.49-1.5V8a1.499 1.499 0 0 0-1.49-1.55h-1.63v-.84a1.5 1.5 0 0 0-1.5-1.5h-3.3a1.51 1.51 0 0 0-.997.385 1.5 1.5 0 0 0-1.003-.385H8.79a1.5 1.5 0 0 0-.998.38 1.51 1.51 0 0 0-.992-.38H3.5A1.5 1.5 0 0 0 2 5.61v12.78a1.5 1.5 0 0 0 1.51 1.5ZM17.88 5.61v.84h-4.3v-.84a.51.51 0 0 1 .5-.5h3.3a.5.5 0 0 1 .5.5Zm-9.583-.084a.5.5 0 0 1 .493-.416h3.29a.5.5 0 0 1 .5.5v.84H8.3v-.84l-.003-.084Zm-1.007.083a.51.51 0 0 0-.5-.499h-3.3a.5.5 0 0 0-.5.5v12.78a.5.5 0 0 0 .5.5h17a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H7.79a.5.5 0 0 1-.5-.5V5.609Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgTabs;
