import React from 'react';

const SvgAlignVerticalCenter = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M22 11h-5V6h-3v5h-4V3H7v8H1.84v2H7v8h3v-8h4v5h3v-5h5v-2Z" />
    </svg>
);
export default SvgAlignVerticalCenter;
