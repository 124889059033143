import * as React from 'react';

const SvgGraphic = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.102 10.532a2.3 2.3 0 1 0 2.556-3.826 2.3 2.3 0 0 0-2.556 3.826Zm.556-2.993a1.3 1.3 0 1 1 1.444 2.162 1.3 1.3 0 0 1-1.444-2.162Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.4 21.9h-1.924a.474.474 0 0 1-.056 0H3.6a1.5 1.5 0 0 1-1.5-1.5V3.6a1.5 1.5 0 0 1 1.5-1.5h16.8a1.5 1.5 0 0 1 1.5 1.5v16.8a1.5 1.5 0 0 1-1.5 1.5Zm0-1h-1.764L8.57 10.46A1.44 1.44 0 0 0 7.5 10a1.45 1.45 0 0 0-1.01.42L3.1 14.004V3.6a.5.5 0 0 1 .5-.5h16.8a.5.5 0 0 1 .5.5v16.8a.5.5 0 0 1-.5.5ZM7.86 11.12l9.384 9.78H3.6a.5.5 0 0 1-.5-.5v-4.946l4.06-4.304a.58.58 0 0 1 .35-.15.53.53 0 0 1 .35.12Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgGraphic;
