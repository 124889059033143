import * as React from 'react';

const SvgMedia = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.25 17a.59.59 0 0 0 .22 0 .47.47 0 0 0 .3-.1l6.08-4.5a.49.49 0 0 0 0-.8L9.8 7.1a.5.5 0 0 0-.8.4v9a.51.51 0 0 0 .25.5Zm.75-1.51v-7L14.69 12 10 15.49Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.46 20h19.08a.5.5 0 0 0 .5-.5v-15a.5.5 0 0 0-.5-.5H2.46a.5.5 0 0 0-.5.5v15a.5.5 0 0 0 .5.5Zm15.52-1H6.02v-2.4a.5.5 0 0 0 0-.2v-2.8a.5.5 0 0 0 0-.2v-2.8a.5.5 0 0 0 0-.2V7.6a.5.5 0 0 0 0-.2V5h11.96v14ZM5.02 14v2H3v-2h2.02Zm0 3H3v2h2.02v-2Zm0-4v-2H3v2h2.02Zm0-5v2H3V8h2.02Zm0-1V5H3v2h2.02Zm13.96 12v-2H21v2h-2.02Zm0-3H21v-2h-2.02v2ZM21 13h-2.02v-2H21v2Zm-2.02-3H21V8h-2.02v2ZM21 7h-2.02V5H21v2Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgMedia;
