import React from 'react';

const SvgInsertSpecialCharacter = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M10.08 10.86c.05-.33.16-.62.3-.87.14-.25.34-.46.59-.62.24-.15.54-.22.91-.23.23.01.44.05.63.13.2.09.38.21.52.36s.25.33.34.53c.09.2.13.42.14.64h1.79c-.02-.471-.11-.9-.28-1.29a3 3 0 0 0-.7-1.011c-.3-.28-.66-.5-1.08-.66-.42-.16-.88-.23-1.39-.23-.65 0-1.22.11-1.7.34-.48.229-.88.53-1.2.92-.32.39-.56.84-.71 1.36-.15.52-.24 1.06-.24 1.64v.27c0 .58.08 1.12.23 1.64.15.52.39.97.71 1.35.321.38.72.69 1.2.91.48.22 1.05.34 1.701.34.47 0 .91-.08 1.32-.23.41-.15.77-.36 1.08-.63.31-.27.56-.58.74-.94.18-.36.29-.74.3-1.15h-1.79c-.01.21-.06.4-.15.58-.09.18-.21.33-.36.46-.15.129-.32.23-.52.3-.19.069-.39.09-.6.099-.36-.01-.66-.08-.89-.23a1.75 1.75 0 0 1-.59-.62c-.14-.251-.25-.55-.3-.88a6.71 6.71 0 0 1-.08-1v-.27c0-.35.03-.68.08-1.01l-.001.002ZM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Z" />
    </svg>
);
export default SvgInsertSpecialCharacter;
