import React from 'react';

const SvgReview = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M7 5H5v14h6v2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h4.18C9.6 1.84 10.7 1 12 1c1.299 0 2.4.84 2.82 2h4.179c1.1 0 2 .9 2 2v5h-2V5h-2v3h-10V5Zm6-1c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1Z" />
        <path d="M15.51 17 21 11.5l1.5 1.5-6.99 7L11 15.5l1.5-1.5 3.01 3Z" />
    </svg>
);
export default SvgReview;
