export default {
    top: 'top',
    topLeft: 'top-left',
    topRight: 'top-right',
    bottom: 'bottom',
    bottomLeft: 'bottom-left',
    bottomRight: 'bottom-right',
    left: 'left',
    right: 'right',
};
