import * as React from 'react';

const SvgDiscussion = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12 13.5a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5A1.5 1.5 0 1 0 10.5 9a.5.5 0 0 1-1 0 2.5 2.5 0 1 1 3 2.45V13a.5.5 0 0 1-.5.5ZM11.673 15.39a.51.51 0 0 0 .327.11.469.469 0 0 0 .35-.15.51.51 0 1 0-.677.04Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 20.5a.5.5 0 0 1-.26-.93 6.2 6.2 0 0 0 2.64-3.18A6.85 6.85 0 0 1 2.5 11a6.11 6.11 0 0 1 .77-3 7.48 7.48 0 0 1 2.06-2.34A10.83 10.83 0 0 1 12 3.5a10.83 10.83 0 0 1 6.67 2.16A7.48 7.48 0 0 1 20.73 8a6.07 6.07 0 0 1 0 5.92 7.48 7.48 0 0 1-2.06 2.38A10.83 10.83 0 0 1 12 18.5a12.129 12.129 0 0 1-3-.38c-.71.463-1.444.887-2.2 1.27A9.001 9.001 0 0 1 3 20.5Zm9-16c-4.69 0-8.5 2.92-8.5 6.5a6 6 0 0 0 2.76 4.79.49.49 0 0 1 .19.54 6.76 6.76 0 0 1-1.58 2.79 17.2 17.2 0 0 0 3.74-2 .52.52 0 0 1 .39-.03c.976.276 1.986.414 3 .41 4.69 0 8.5-2.92 8.5-6.5S16.69 4.5 12 4.5Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgDiscussion;
