import React, { useLayoutEffect } from 'react';
import { func, number, object, shape, string } from 'prop-types';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import scrollparent from 'scrollparent';
import TOOLTIP_POSITIONS from '../types/tooltipPositions';

/**
 * Because the requestedPositionType might not always be the best position,
 * for example if the element that's displaying the tooltip is near the top
 * of the viewport then 'top' wouldn't be the best choice
 */
const getBestPositionType = (requestedPositionType, { top, left }) => {
    const { innerHeight, innerWidth } = window;
    const constraint = 250;
    let bestPositionType = requestedPositionType;

    if (top < constraint) {
        if (requestedPositionType === TOOLTIP_POSITIONS.top) {
            bestPositionType = TOOLTIP_POSITIONS.bottom;
        }
        if (requestedPositionType === TOOLTIP_POSITIONS.topLeft) {
            bestPositionType = TOOLTIP_POSITIONS.bottomLeft;
        }
    }

    if (top > innerHeight - constraint) {
        if (requestedPositionType === TOOLTIP_POSITIONS.bottom) {
            bestPositionType = TOOLTIP_POSITIONS.top;
        }
        if (requestedPositionType === TOOLTIP_POSITIONS.bottomLeft) {
            bestPositionType = TOOLTIP_POSITIONS.topLeft;
        }
    }

    if (left < constraint) {
        if (bestPositionType === TOOLTIP_POSITIONS.left) {
            bestPositionType = TOOLTIP_POSITIONS.right;
        }
        if (bestPositionType === TOOLTIP_POSITIONS.topLeft) {
            bestPositionType = TOOLTIP_POSITIONS.topRight;
        }
        if (bestPositionType === TOOLTIP_POSITIONS.bottomLeft) {
            bestPositionType = TOOLTIP_POSITIONS.bottomRight;
        }
    }

    if (left > innerWidth - constraint) {
        if (bestPositionType === TOOLTIP_POSITIONS.right) {
            bestPositionType = TOOLTIP_POSITIONS.left;
        }
        if (bestPositionType === TOOLTIP_POSITIONS.topRight) {
            bestPositionType = TOOLTIP_POSITIONS.topLeft;
        }
        if (bestPositionType === TOOLTIP_POSITIONS.bottomRight) {
            bestPositionType = TOOLTIP_POSITIONS.bottomLeft;
        }
    }
    return bestPositionType;
};

const getPosition = (positionType, offset, { top, left, height, width }) => {
    const spacing = 6;
    let topPosition;
    let leftPosition;

    switch (positionType) {
        case TOOLTIP_POSITIONS.top:
            topPosition = top - spacing;
            leftPosition = left + width / 2;
            break;
        case TOOLTIP_POSITIONS.topLeft:
            topPosition = top - spacing;
            leftPosition = left + width;
            break;
        case TOOLTIP_POSITIONS.topRight:
            topPosition = top - spacing;
            leftPosition = left;
            break;
        case TOOLTIP_POSITIONS.bottom:
            topPosition = top + height + spacing;
            leftPosition = left + width / 2;
            break;
        case TOOLTIP_POSITIONS.bottomLeft:
            topPosition = top + height + spacing;
            leftPosition = left + width;
            break;
        case TOOLTIP_POSITIONS.bottomRight:
            topPosition = top + height + spacing;
            leftPosition = left;
            break;
        case TOOLTIP_POSITIONS.left:
            topPosition = top + height / 2;
            leftPosition = left - spacing;
            break;
        case TOOLTIP_POSITIONS.right:
            topPosition = top + height / 2;
            leftPosition = left + width + spacing;
            break;
        default:
            topPosition = top;
            leftPosition = left;
    }

    return {
        top: `${topPosition + offset.y}px`,
        left: `${leftPosition + offset.x}px`,
    };
};

const Tooltip = ({ tooltip, help, refCurrent, positionType, offset, setVisible }) => {
    useLayoutEffect(() => {
        function handleScroll() {
            setVisible(false);
        }
        const scrollableParent = scrollparent(refCurrent);
        scrollableParent?.addEventListener('scroll', handleScroll);

        return () => scrollableParent?.removeEventListener('scroll', handleScroll);
    }, [setVisible, refCurrent]);

    useLayoutEffect(() => {
        function onVisibilityChange() {
            setVisible(false);
        }
        document.addEventListener('visibilitychange', onVisibilityChange);

        return () => document.removeEventListener('visibilitychange', onVisibilityChange);
    }, [setVisible]);

    if (!refCurrent) return null;

    const domRect = refCurrent.getBoundingClientRect();
    const bestPositionType = getBestPositionType(positionType, domRect);
    const { top, left } = getPosition(bestPositionType, offset, domRect);

    return ReactDOM.createPortal(
        <div
            className={classnames(`tooltip is-${bestPositionType}`, {
                'has-tooltip-text': tooltip,
            })}
            style={{
                top,
                left,
            }}
        >
            {tooltip && <div className="tooltip-text">{tooltip}</div>}
            {help && <div className="tooltip-help">{help}</div>}
        </div>,
        document.getElementById('wrapper')
    );
};

Tooltip.propTypes = {
    tooltip: string,
    help: string,
    refCurrent: object,
    positionType: string,
    offset: shape({
        x: number,
        y: number,
    }),
    setVisible: func.isRequired,
};

Tooltip.defaultProps = {
    positionType: TOOLTIP_POSITIONS.bottom,
    offset: { x: 0, y: 0 },
    setVisible: () => {},
};

export default Tooltip;
