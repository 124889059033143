import React from 'react';
import { usePublicPath } from '../../../publicPath';

const AuthFormLogo = () => (
    <div className="authentication-form-logo">
        <img
            className="authentication-form-logo-image"
            src={`${usePublicPath()}/assets/evolve_full_large.png`}
            alt="Evolve"
        />
    </div>
);

export default AuthFormLogo;
