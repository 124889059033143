import React from 'react';

const AuthFormPolicies = () => (
    <div className="authentication-policies">
        By having an account or using the Evolve service you agree to be bound by the following
        terms and conditions:{' '}
        <a target="_blank" rel="noreferrer" href="https://www.intellum.com/policies/home">
            Terms of service, Privacy policy & Data processing agreement
        </a>
    </div>
);

export default AuthFormPolicies;
