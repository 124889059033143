import React from 'react';

const SvgTabletLandscape = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M24 18.5v-14c0-1.38-1.12-2.5-2.5-2.5h-19c-1.38 0-2.5 1.12-2.5 2.5v14c0 1.38 1.12 2.5 2.5 2.5h19c1.38 0 2.5-1.12 2.5-2.5zM1 11.5c0-0.83 0.67-1.5 1.5-1.5s1.5 0.67 1.5 1.5c0 0.83-0.67 1.5-1.5 1.5s-1.5-0.67-1.5-1.5zM5 19v-15h16v15h-16z" />
    </svg>
);
export default SvgTabletLandscape;
