import React from 'react';

const SvgExpandOff = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M22 3.41 16.71 8.7 20 12h-8V4l3.29 3.29L20.59 2 22 3.41ZM3.41 22l5.29-5.29L12 20v-8H4l3.29 3.29L2 20.59 3.41 22Z" />
    </svg>
);
export default SvgExpandOff;
