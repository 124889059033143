import * as React from 'react';

const SvgLinkedOpenInput = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15.48 14.17a.492.492 0 0 0 .71 0l.58-.58a.5.5 0 0 0-.35-.853.5.5 0 0 0-.35.143l-.59.58a.51.51 0 0 0 0 .71ZM13.92 14.24a.54.54 0 0 1-.36-.14.51.51 0 0 1 0-.71L16 11a.502.502 0 0 1 .71.71l-2.44 2.39a.52.52 0 0 1-.35.14Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.28 16.08h-1.8A1.5 1.5 0 0 1 2 14.58V5.63a1.5 1.5 0 0 1 1.5-1.5h13.72a1.5 1.5 0 0 1 1.5 1.5v1.58h1.8A1.5 1.5 0 0 1 22 8.76v9a1.5 1.5 0 0 1-1.48 1.45H6.78a1.5 1.5 0 0 1-1.5-1.5v-1.63Zm1 0h10.94a1.5 1.5 0 0 0 1.5-1.45V8.21h1.8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5H6.78a.5.5 0 0 1-.5-.5v-1.63Zm-.718-.95H3.48a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13.74a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5H5.998a.5.5 0 0 0-.436 0Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgLinkedOpenInput;
