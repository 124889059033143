import * as React from 'react';

const SvgCourseAssessmentResults = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.65 4.78a.5.5 0 0 0 .35.14.5.5 0 0 0 .35-.14.39.39 0 0 0 .11-.17.41.41 0 0 0 0-.19.429.429 0 0 0 0-.19.36.36 0 0 0-.11-.16.5.5 0 0 0-.7 0 .36.36 0 0 0-.11.16.432.432 0 0 0 0 .19.412.412 0 0 0 0 .19.39.39 0 0 0 .11.17Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.766 6.684a.5.5 0 0 0 .354.146h7.76a.5.5 0 0 0 .52-.49 2.37 2.37 0 0 0-2-2.34 2.45 2.45 0 0 0-4.8 0 2.37 2.37 0 0 0-1.98 2.33.5.5 0 0 0 .146.354Zm7.005-1.465c.23.148.41.36.519.611H8.71A1.41 1.41 0 0 1 10.05 5a.51.51 0 0 0 .5-.5 1.44 1.44 0 1 1 2.88 0 .51.51 0 0 0 .5.5H14a1.38 1.38 0 0 1 .77.22Z"
            fill="#393B3D"
        />
        <path
            d="M5.2 21.81h13.6a1.53 1.53 0 0 0 1.52-1.52V6.38a1.53 1.53 0 0 0-1.52-1.52h-1.05a.51.51 0 0 0-.5.5.5.5 0 0 0 .5.5h1.05a.52.52 0 0 1 .52.52v13.91a.53.53 0 0 1-.52.52H5.2a.53.53 0 0 1-.52-.52V6.38a.52.52 0 0 1 .52-.52h1a.5.5 0 0 0 .5-.5.51.51 0 0 0-.5-.5h-1a1.53 1.53 0 0 0-1.52 1.52v13.91a1.53 1.53 0 0 0 1.52 1.52Z"
            fill="#393B3D"
        />
        <path
            d="M10.68 15.72a.54.54 0 0 1-.35-.14L8.73 14a.51.51 0 0 1 0-.71.5.5 0 0 1 .71 0l1.24 1.24 3.88-3.87a.5.5 0 0 1 .853.35.5.5 0 0 1-.143.35L11 15.58a.54.54 0 0 1-.32.14Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgCourseAssessmentResults;
