import React from 'react';

const SvgSnap = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M12 10h9V3h-9c-5 0-9 4-9 9s4 9 9 9h9v-7h-9c-1.1 0-2-.9-2-2s.9-2 2-2Zm4-5h3v3h-3V5Zm0 11h3v3h-3v-3Zm-4 0h2v3h-2c-3.9 0-7-3.1-7-7s3.1-7 7-7h2v3h-2c-2.2 0-4 1.8-4 4s1.8 4 4 4Z" />
    </svg>
);
export default SvgSnap;
