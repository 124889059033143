import React from 'react';

const SvgTemplates = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M3 5v14a2 2 0 0 0 2 2h6V3H5a2 2 0 0 0-2 2.001Zm6 14H5V5h4v14ZM19 3h-6v8h8V5c0-1.1-.9-2-2-2Zm0 6h-4V5h4v4ZM19 21h-6v-8h8v6c0 1.1-.9 2-2 2Zm0-6h-4v4h4v-4Z" />
    </svg>
);
export default SvgTemplates;
