import React from 'react';

const SvgIndentIncrease = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M3 21h18v-2H3v2ZM3 8v8l4-4-4-3.999Zm8 9h10v-2H11v2ZM3 3v2h18V3H3Zm8 6h10V7H11v2Zm0 4h10v-2H11v2Z" />
    </svg>
);
export default SvgIndentIncrease;
