import * as React from 'react';

const SvgMediaGrid = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M8.05 7.62 5.74 5.91v3.43l2.31-1.72Z" fill="#393B3D" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.48 11.48H11a.5.5 0 0 0 .5-.48V4.27a.5.5 0 0 0-.5-.5H2.5a.5.5 0 0 0-.5.5V11a.5.5 0 0 0 .48.48Zm8.02-1H3V4.77h7.5v5.71Z"
            fill="#393B3D"
        />
        <path d="m18.57 7.62-2.31-1.71v3.43l2.31-1.72Z" fill="#393B3D" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.52 11.48H13a.5.5 0 0 1-.5-.5V4.27a.5.5 0 0 1 .5-.5h8.52a.5.5 0 0 1 .5.5V11a.5.5 0 0 1-.5.48Zm-8-1H21V4.77h-7.5l.02 5.71Z"
            fill="#393B3D"
        />
        <path d="m8.05 16.38-2.31-1.72v3.43l2.31-1.71Z" fill="#393B3D" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 20.23H2.48a.5.5 0 0 1-.5-.5V13a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 .5.5v6.7a.5.5 0 0 1-.5.53Zm-8-1h7.5v-5.7H3v5.7Z"
            fill="#393B3D"
        />
        <path d="m18.57 16.38-2.31-1.72v3.43l2.31-1.71Z" fill="#393B3D" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.52 20.23H13a.5.5 0 0 1-.5-.5V13a.5.5 0 0 1 .5-.5h8.52a.5.5 0 0 1 .5.5v6.7a.5.5 0 0 1-.5.53Zm-8-1H21v-5.7h-7.5l.02 5.7Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgMediaGrid;
