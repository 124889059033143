import * as React from 'react';

const SvgMediaCarousel = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.88 12.2a.46.46 0 0 0 .22.06.54.54 0 0 0 .3-.1l2.81-2.09a.5.5 0 0 0 0-.8L11.4 7.18a.5.5 0 0 0-.8.4v4.18a.489.489 0 0 0 .28.44Zm.72-1.44V8.57l1.47 1.1-1.47 1.09Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.48 16.61h11a.5.5 0 0 0 .5-.5V5.61a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v10.5a.5.5 0 0 0 .5.5Zm10.52-1v-1.28H7v1.28h10ZM7 13.33h10V6.11H7v7.22Z"
            fill="#393B3D"
        />
        <path
            d="M19.7 13.1a.54.54 0 0 0 .36.14.492.492 0 0 0 .35-.15l1.88-1.88a.481.481 0 0 0 0-.7l-1.88-1.88a.5.5 0 0 0-.71 0 .5.5 0 0 0 0 .7l1.53 1.53-1.53 1.53a.51.51 0 0 0 0 .71ZM3.94 13.24a.491.491 0 0 1-.35-.15l-1.88-1.88a.48.48 0 0 1 0-.7l1.88-1.88a.5.5 0 0 1 .853.35.5.5 0 0 1-.143.35l-1.53 1.53 1.53 1.53a.51.51 0 0 1 0 .71.54.54 0 0 1-.36.14ZM10.11 18.88a.51.51 0 1 1-1.02 0 .51.51 0 0 1 1.02 0ZM12 19.39a.51.51 0 1 0 0-1.02.51.51 0 0 0 0 1.02ZM14.91 18.88a.51.51 0 1 1-1.02 0 .51.51 0 0 1 1.02 0Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgMediaCarousel;
