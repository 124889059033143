import React from 'react';
import { string } from 'prop-types';
import { Typography } from 'uikit/typography';

const AuthFormError = ({ error, spacing, textAlign }) => (
    <Typography
        spacing={spacing}
        textAlign={textAlign}
        fontStyle="x-small"
        theme="warning"
        className="authentication-form-error"
    >
        {error || ''}
    </Typography>
);

AuthFormError.propTypes = {
    error: string,
    spacing: string,
    textAlign: string,
};

AuthFormError.defaultProps = {
    textAlign: 'center',
};

export default AuthFormError;
