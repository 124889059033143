import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from 'uikit/icons';
import { Button, ButtonTooltip } from '../../button';

const contextMenuPositions = {
    top: 'top',
    topLeft: 'top-left',
    topRight: 'top-right',
    bottom: 'bottom',
    bottomLeft: 'bottom-left',
    bottomRight: 'bottom-right',
    left: 'left',
    right: 'right',
};

function ContextMenuPopup({
    buttonPosition,
    menuPosition,
    menuWidth,
    menuOffset,
    menuTitle,
    menuSubtitle,
    actions,
    setIsOpen,
    onContextMenuOpen,
    CustomMenu,
    customMenuProps,
    className,
}) {
    const [position, setPosition] = useState({ menuPosition, top: 0, left: 0 });
    const constraint = 24;
    const spacing = 6;

    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            const { height, width } = ref.current.getBoundingClientRect();
            const { x, y, h, w } = buttonPosition;
            const { innerHeight, innerWidth } = window;
            let _menuPosition = menuPosition;
            let topPosition = 0;
            let leftPosition = 0;
            if (y < constraint) {
                if (menuPosition === contextMenuPositions.top) {
                    _menuPosition = contextMenuPositions.bottom;
                }
                if (menuPosition === contextMenuPositions.topLeft) {
                    _menuPosition = contextMenuPositions.bottomLeft;
                }
                if (menuPosition === contextMenuPositions.topRight) {
                    _menuPosition = contextMenuPositions.bottomRight;
                }
            }

            if (y + height > innerHeight - constraint) {
                if (menuPosition === contextMenuPositions.bottom) {
                    _menuPosition = contextMenuPositions.top;
                }
                if (menuPosition === contextMenuPositions.bottomLeft) {
                    _menuPosition = contextMenuPositions.topLeft;
                }
                if (menuPosition === contextMenuPositions.bottomRight) {
                    _menuPosition = contextMenuPositions.topRight;
                }
            }

            if (x < constraint) {
                if (_menuPosition === contextMenuPositions.left) {
                    _menuPosition = contextMenuPositions.right;
                }
                if (_menuPosition === contextMenuPositions.topLeft) {
                    _menuPosition = contextMenuPositions.topRight;
                }
                if (_menuPosition === contextMenuPositions.bottomLeft) {
                    _menuPosition = contextMenuPositions.bottomRight;
                }
            }

            if (x + width > innerWidth - constraint) {
                if (_menuPosition === contextMenuPositions.right) {
                    _menuPosition = contextMenuPositions.left;
                }
                if (_menuPosition === contextMenuPositions.topRight) {
                    _menuPosition = contextMenuPositions.topLeft;
                }
                if (_menuPosition === contextMenuPositions.bottomRight) {
                    _menuPosition = contextMenuPositions.bottomLeft;
                }
            }

            switch (_menuPosition) {
                case contextMenuPositions.top:
                    topPosition = y - spacing;
                    leftPosition = x + w / 2;
                    break;
                case contextMenuPositions.topLeft:
                    topPosition = y - spacing;
                    leftPosition = x + w;
                    break;
                case contextMenuPositions.topRight:
                    topPosition = y - spacing;
                    leftPosition = x;
                    break;
                case contextMenuPositions.bottom:
                    topPosition = y + h + spacing;
                    leftPosition = x + w / 2;
                    break;
                case contextMenuPositions.bottomLeft:
                    topPosition = y + h + spacing;
                    leftPosition = x + w;
                    break;
                case contextMenuPositions.bottomRight:
                    topPosition = y + h + spacing;
                    leftPosition = x;
                    break;
                case contextMenuPositions.left:
                    topPosition = y + h / 2;
                    leftPosition = x - spacing;
                    break;
                case contextMenuPositions.right:
                    topPosition = y + h / 2;
                    leftPosition = x + w + spacing;
                    break;
                default:
                    topPosition = y;
                    leftPosition = x;
            }

            setPosition({ menuPosition: _menuPosition, top: topPosition, left: leftPosition });
        }
    }, [buttonPosition, menuPosition, ref]);

    const menuActions = useMemo(() => {
        return _.filter(actions, ({ isVisible = true }) => isVisible);
    }, [actions]);

    return ReactDOM.createPortal(
        <div
            className="context-menu-popup-container"
            onClick={event => {
                event.stopPropagation();
                setIsOpen(false);
                onContextMenuOpen(false);
            }}
        >
            <div
                ref={ref}
                className={classnames(
                    'context-menu-popup',
                    `is-${position.menuPosition}`,
                    className,
                    {
                        'has-header': menuTitle,
                    }
                )}
                style={{
                    top: `${position.top + menuOffset.y}px`,
                    left: `${position.left + menuOffset.x}px`,
                    width: `${menuWidth}px`,
                }}
            >
                <div className="content-menu-popup-inner">
                    {CustomMenu && (
                        <CustomMenu
                            {...customMenuProps}
                            closeMenu={() => {
                                setIsOpen(false);
                                onContextMenuOpen(false);
                            }}
                        />
                    )}
                    {!CustomMenu && (
                        <>
                            {menuTitle && (
                                <div className="content-menu-popup-header">
                                    {menuTitle && (
                                        <div className="content-menu-popup-header-title">
                                            {menuTitle}
                                        </div>
                                    )}
                                    {menuSubtitle && (
                                        <div className="content-menu-popup-header-sub-title">
                                            {menuSubtitle}
                                        </div>
                                    )}
                                </div>
                            )}
                            {_.map(
                                menuActions,
                                ({
                                    icon,
                                    text,
                                    onClick,
                                    type = 'secondary',
                                    isDisabled = false,
                                    hasBorderTop = false,
                                    isActive = false,
                                    iconType = 'default',
                                }) => (
                                    <Button
                                        className={classnames('context-menu-popup-button', {
                                            'has-border-top': hasBorderTop,
                                        })}
                                        key={text}
                                        icon={icon}
                                        iconType={iconType}
                                        text={text}
                                        isFlat={true}
                                        type={type}
                                        isDisabled={isDisabled}
                                        isActive={isActive}
                                        onClick={() => {
                                            setIsOpen(false);
                                            onContextMenuOpen(false);
                                            onClick();
                                        }}
                                    />
                                )
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>,
        document.getElementById('wrapper')
    );
}

/**
 * @param {React.PropsWithChildren<{
 *  menuPosition?: 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right' | 'left' | 'right'
 *  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'warning' | 'dark'
 *  buttonSize?: 'regular' | 'small' | 'medium' | 'large' | 'custom'
 *  tooltipPosition?: 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right' | 'left' | 'right'
 *  menuOffset?: { x: number, y: number }
 *  menuWidth?: number
 *  text?: string
 *  icon?: string
 *  tooltip?: string
 *  menuTitle?: string
 *  menuSubtitle?: string
 *  className?: string
 *  buttonClassName?: string
 *  popupClassName?: string
 *  buttonIconPosition?: 'right' | 'left'
 *  useButtonTooltip?: boolean
 *  useButtonBackgroundHoverColor?: boolean
 *  useArrowIndicator?: boolean
 *  CustomMenu?: React.ComponentType
 *  customMenuProps?: {[key: string]: any }
 *  onContextMenuOpen?: (isOpen: boolean) => void
 *  actions?: { text: string, icon: string, type?: string, isDisabled?: boolean, hasBorderTop?: boolean, onClick: () => void }[]
 * }>} props
 */
function ContextMenu({
    className,
    buttonType,
    buttonClassName,
    popupClassName,
    useButtonTooltip,
    menuPosition,
    buttonSize,
    buttonIconPosition,
    useButtonBackgroundHoverColor,
    useArrowIndicator,
    icon,
    statusIcon,
    statusIconType,
    tooltip,
    tooltipPosition,
    text,
    onContextMenuOpen,
    actions,
    menuWidth,
    menuOffset,
    menuTitle,
    menuSubtitle,
    CustomMenu,
    customMenuProps,
}) {
    const ref = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0, h: 23, w: 23 });

    const ButtonComponent = useButtonTooltip ? ButtonTooltip : Button;

    const buttonProps = {};

    if (useButtonTooltip) {
        buttonProps.tooltip = tooltip;
        buttonProps.tooltipPosition = tooltipPosition;
        buttonProps.type = buttonType;
    } else {
        buttonProps.isFlat = true;
        buttonProps.type = 'secondary';
        buttonProps.iconPosition = buttonIconPosition;
    }

    const onOpenClick = () => {
        if (ref.current) {
            const { top, left, width, height } = ref.current.getBoundingClientRect();
            setButtonPosition({ x: left, y: top, h: height, w: width });
        }
        setIsOpen(!isOpen);
        onContextMenuOpen(!isOpen);
    };

    return (
        <div
            ref={ref}
            className={classnames('context-menu', className, {
                'has-arrow-indicator': useArrowIndicator,
            })}
        >
            {statusIcon && (
                <i
                    className={`icon icon-${statusIcon} icon-type-${statusIconType} context-menu-status-icon`}
                />
            )}
            <ButtonComponent
                className={classnames('context-menu-button', buttonClassName)}
                icon={icon}
                text={text}
                onClick={onOpenClick}
                isDisabled={isOpen}
                size={buttonSize}
                useBackgroundHoverColor={useButtonBackgroundHoverColor}
                {...buttonProps}
            >
                {useArrowIndicator && (
                    <Icon
                        className="context-menu-arrow-indicator"
                        icon={classnames({
                            triangleArrowUp: isOpen,
                            triangleArrowDown: !isOpen,
                        })}
                    />
                )}
            </ButtonComponent>
            {isOpen && (
                <ContextMenuPopup
                    className={popupClassName}
                    actions={actions}
                    setIsOpen={setIsOpen}
                    onContextMenuOpen={onContextMenuOpen}
                    menuPosition={menuPosition}
                    menuWidth={menuWidth}
                    menuOffset={menuOffset}
                    buttonPosition={buttonPosition}
                    menuTitle={menuTitle}
                    menuSubtitle={menuSubtitle}
                    CustomMenu={CustomMenu}
                    customMenuProps={customMenuProps}
                />
            )}
        </div>
    );
}

ContextMenu.propTypes = {
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
    buttonType: PropTypes.string,
    useButtonTooltip: PropTypes.bool,
    menuPosition: PropTypes.string,
    buttonSize: PropTypes.string,
    buttonIconPosition: PropTypes.string,
    useButtonBackgroundHoverColor: PropTypes.bool,
    useArrowIndicator: PropTypes.bool,
    icon: PropTypes.string,
    statusIcon: PropTypes.string,
    statusIconType: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipPosition: PropTypes.string,
    text: PropTypes.string,
    onContextMenuOpen: PropTypes.func,
    actions: PropTypes.array,
    menuWidth: PropTypes.number,
    menuOffset: PropTypes.object,
    menuTitle: PropTypes.string,
    menuSubtitle: PropTypes.string,
    CustomMenu: PropTypes.elementType,
    customMenuProps: PropTypes.object,
    popupClassName: PropTypes.string,
};

ContextMenu.defaultProps = {
    useButtonTooltip: true,
    menuPosition: contextMenuPositions.bottomRight,
    buttonSize: 'regular',
    useButtonBackgroundHoverColor: false,
    useArrowIndicator: false,
    icon: 'additionalOptions',
    tooltip: 'View Actions',
    menuWidth: 180,
    menuOffset: { x: 0, y: 0 },
    onContextMenuOpen: () => {},
    customMenuProps: {},
};

export default ContextMenu;
