import React from 'react';

const SvgCloudSyncOn = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#cloudSyncOn_svg__a)">
            <path d="M19.35 10.04A7.49 7.49 0 0 0 12 4C9.11 4 6.6 5.64 5.35 8.04A5.995 5.995 0 0 0 0 14.002c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96ZM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.501-.95A5.47 5.47 0 0 1 12.001 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11a2.98 2.98 0 0 1 2.78 2.96c0 1.65-1.35 3-3 3H19Zm-9-3.82-2.09-2.09L6.5 13.5 10 17l6.01-6.01-1.41-1.41-4.6 4.6Z" />
        </g>
        <defs>
            <clipPath id="cloudSyncOn_svg__a">
                <path d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgCloudSyncOn;
