import React from 'react';

const SvgCourses = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M12 3 1 9l4 2.18v6L12 21l6.999-3.82v-6l2-1.09V17h2V9L12 3H12Zm6.82 6L12 12.72 5.18 9 12 5.28 18.82 9ZM17 15.99l-5 2.73-5-2.73v-3.72L12 15l5-2.73v3.72Z" />
    </svg>
);
export default SvgCourses;
