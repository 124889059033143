import * as React from 'react';

const SvgCheckboxGroup = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.81 15.727a.46.46 0 0 0 .19.033.46.46 0 0 0 .35-.14l6.53-6.53a.501.501 0 0 0 0-.71.5.5 0 0 0-.7 0L10 14.56l-2.15-2.12a.5.5 0 0 0-.848.355.5.5 0 0 0 .148.355l2.5 2.47a.46.46 0 0 0 .16.107Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 20.53h14A1.509 1.509 0 0 0 20.53 19V5A1.51 1.51 0 0 0 19 3.47H5A1.51 1.51 0 0 0 3.47 5v14A1.51 1.51 0 0 0 5 20.53ZM4.65 4.62A.51.51 0 0 1 5 4.47l14 .03a.51.51 0 0 1 .5.5v14a.51.51 0 0 1-.5.5H5a.51.51 0 0 1-.5-.5V4.97a.51.51 0 0 1 .15-.35Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgCheckboxGroup;
