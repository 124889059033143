import * as React from 'react';

const SvgCardDrop = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.91 7.4V6.2a1.5 1.5 0 0 0-1.5-1.5H16V3.5A1.5 1.5 0 0 0 14.54 2H3.73a1.5 1.5 0 0 0-1.5 1.5v11.6a1.5 1.5 0 0 0 1.5 1.5h1.36v1.2a1.5 1.5 0 0 0 1.5 1.5H8v1.2A1.5 1.5 0 0 0 9.46 22h10.81a1.5 1.5 0 0 0 1.5-1.5V8.9a1.5 1.5 0 0 0-1.5-1.5h-1.36Zm-4.37 9.2H6.09v1.2a.5.5 0 0 0 .5.5h10.82a.5.5 0 0 0 .5-.5V6.2a.5.5 0 0 0-.5-.5H16v9.4a1.5 1.5 0 0 1-1.46 1.5Zm4.37-8.2v9.4a1.5 1.5 0 0 1-1.5 1.5H9v1.2a.5.5 0 0 0 .5.5h10.77a.5.5 0 0 0 .5-.5V8.9a.5.5 0 0 0-.5-.5h-1.36ZM3.73 3a.5.5 0 0 0-.5.5v11.6a.5.5 0 0 0 .5.5h10.81a.5.5 0 0 0 .5-.5V3.5a.5.5 0 0 0-.5-.5H3.73Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgCardDrop;
