import * as React from 'react';

const SvgImageSlider = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.167 9.642a1.59 1.59 0 1 0 1.766-2.644 1.59 1.59 0 0 0-1.766 2.644Zm.555-1.813a.59.59 0 0 1 .328-.099.58.58 0 0 1 .59.59.59.59 0 1 1-.918-.49Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.29 16.58h9.42a1.5 1.5 0 0 0 1.5-1.5V5.66a1.5 1.5 0 0 0-1.5-1.5H7.29a1.5 1.5 0 0 0-1.5 1.5v9.42a1.5 1.5 0 0 0 1.5 1.5Zm0-1h7.439L9.34 10H9.2l-2.41 2.552v2.528a.5.5 0 0 0 .5.5Zm2.77-6.3 6.056 6.3h.594a.5.5 0 0 0 .5-.5V5.66a.5.5 0 0 0-.5-.5H7.29a.5.5 0 0 0-.5.5v5.43l1.68-1.8c.218-.206.5-.33.8-.35a1.07 1.07 0 0 1 .79.34Z"
            fill="#393B3D"
        />
        <path
            d="M3.59 13.1a.52.52 0 0 0 .71 0 .51.51 0 0 0 0-.71l-1.53-1.53L4.3 9.33a.5.5 0 0 0-.355-.848.5.5 0 0 0-.355.148l-1.88 1.88a.48.48 0 0 0 0 .7l1.88 1.89ZM19.7 13.1a.52.52 0 0 0 .71 0l1.88-1.89a.481.481 0 0 0 0-.7l-1.88-1.88a.5.5 0 0 0-.71 0 .5.5 0 0 0 0 .7l1.53 1.53-1.53 1.53a.51.51 0 0 0 0 .71ZM9.6 19.39a.51.51 0 1 0 0-1.02.51.51 0 0 0 0 1.02ZM12.51 18.88a.51.51 0 1 1-1.02 0 .51.51 0 0 1 1.02 0ZM14.4 19.39a.51.51 0 1 0 0-1.02.51.51 0 0 0 0 1.02Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgImageSlider;
