import React from 'react';

const SvgLineHeight = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M6 7h2.5L5 3.5 1.5 7h2.499v10h-2.5L5 20.5 8.5 17H6V7Zm4-2v2h12V5H10Zm0 14h12v-2H10v2Zm0-6h12v-2H10v2Z" />
    </svg>
);
export default SvgLineHeight;
