import React from 'react';

const SvgLink = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5.001 5 5.001h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1V12ZM8 13h8v-2H8v2Zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1 0 1.71-1.39 3.099-3.1 3.099h-4v1.9h4c2.76 0 5-2.24 5-5s-2.24-5-5-5Z" />
    </svg>
);
export default SvgLink;
