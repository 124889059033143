import * as React from 'react';

const SvgVideoStream = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.13 18.55a1.15 1.15 0 0 1-.72-.28L17 15.51a2.821 2.821 0 0 1-1-2v-3a2.82 2.82 0 0 1 1-2l3.44-2.76c.2-.174.455-.272.72-.28.43 0 .87.32.87 1v11a1.15 1.15 0 0 1-.19.69.82.82 0 0 1-.68.36l-.03.03ZM21 6.54l-3.41 2.73A1.84 1.84 0 0 0 17 10.5v3c.032.47.243.91.59 1.23L21 17.46V6.54ZM6.25 15.44a.57.57 0 0 0 .25.06.48.48 0 0 0 .26-.07l5-3a.5.5 0 0 0 .175-.676.509.509 0 0 0-.175-.184l-5-3a.52.52 0 0 0-.51 0A.5.5 0 0 0 6 9v6a.52.52 0 0 0 .25.44ZM7 14.12V9.88L10.53 12 7 14.12Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5 18.5h-10A1.5 1.5 0 0 1 2 17V7a1.5 1.5 0 0 1 1.5-1.5h10A1.5 1.5 0 0 1 15 7v10a1.5 1.5 0 0 1-1.5 1.5Zm-10-12A.5.5 0 0 0 3 7v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V7a.5.5 0 0 0-.5-.5h-10Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgVideoStream;
