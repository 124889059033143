import React from 'react';

const SvgAlignLeft = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M4 22H2V2h2v20ZM22 7H6v3h16V7Zm-6 7H6v3h10v-3Z" />
    </svg>
);
export default SvgAlignLeft;
