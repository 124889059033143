import React, { useRef, useState } from 'react';
import { bool, func, number, object, oneOf, shape, string } from 'prop-types';
import classnames from 'classnames';
import { NavLink, Link } from 'react-router-dom';
import { Tooltip } from '../../tooltip';
import { Icon } from '../../icons';

const sizes = {
    regular: 'regular',
    small: 'small',
    medium: 'medium',
    large: 'large',
    custom: 'custom',
};

const buttonTypes = {
    default: 'default',
    primary: 'primary',
    secondary: 'secondary',
    warning: 'warning',
    dark: 'dark',
};

const linkTypes = {
    link: 'link',
    navlink: 'navlink',
    external: 'external',
};

const selectedBorderSides = {
    left: 'left',
    bottom: 'bottom',
};

function getLinkElement(linkType) {
    if (linkType === linkTypes.external) {
        return 'a';
    }
    if (linkType === linkTypes.navlink) {
        return NavLink;
    }
    return Link;
}

/**
 * @param {React.PropsWithChildren<{
 *  className?: string
 *  testId?: string
 *  text?: string
 *  icon?: string
 *  useEvIcon?: boolean
 *  imgSrc?: string
 *  imgAlt?: string
 *  imgSize?: number
 *  useImgIcon?: boolean
 *  tooltip?: string
 *  help?: string
 *  tooltipPosition?: 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right' | 'left' | 'right'
 *  tooltipOffset?: { x: number, y: number }
 *  size?: 'regular' | 'small' | 'medium' | 'large' | 'custom'
 *  type?: 'default' | 'primary' | 'secondary' | 'tertiary' | 'warning' | 'dark'
 *  isLink?: boolean
 *  linkType?: 'link' | 'navlink' | 'external';
 *  isIndicator?: boolean
 *  isControl?: boolean
 *  buttonCount?: number
 *  isSelected?: boolean
 *  selectedBorderSide?: 'left' | 'bottom'
 *  useSelectedBackground?: boolean
 *  useBackgroundHoverColor?: boolean
 *  isDisabled?: boolean
 *  onClick?: function
 *  to?: string
 *  stopPropagation?: boolean
 *  preventDefault?: boolean
 *  style?: object
 * }>} props
 */
function ButtonTooltip({
    className,
    testId,
    text,
    icon,
    useEvIcon,
    imgSrc,
    imgAlt,
    imgSize,
    useImgIcon,
    tooltip,
    help,
    tooltipPosition,
    tooltipOffset,
    size,
    type,
    isLink,
    linkType,
    isIndicator,
    isControl,
    buttonCount,
    isSelected,
    selectedBorderSide,
    useSelectedBackground,
    useBackgroundHoverColor,
    isDisabled,
    onClick,
    to,
    stopPropagation,
    preventDefault,
    style,
    children,
}) {
    const ref = useRef(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const onButtonClick = event => {
        setTooltipVisible(false);
        event.currentTarget.blur();
        if (onClick && !isIndicator) {
            if (stopPropagation) event.stopPropagation();
            if (preventDefault) event.preventDefault();
            onClick(event);
        }
    };

    const getButtonProps = () => {
        const buttonProps = { style, ref };
        if (!isLink) {
            buttonProps.disabled = isDisabled;
            return buttonProps;
        }

        if (linkType === linkTypes.external) {
            buttonProps.href = to;
            buttonProps.target = '_blank';
            buttonProps.rel = 'noreferrer';
            return buttonProps;
        }

        buttonProps.to = to;
        if (linkType === linkTypes.navlink) {
            buttonProps.activeClassName = 'is-selected';
            buttonProps.exact = false;
        }
        return buttonProps;
    };

    const renderIcon = () => {
        if (useImgIcon) {
            return (
                <img
                    src={imgSrc}
                    alt={imgAlt}
                    style={{ width: `${imgSize}px`, height: `${imgSize}px` }}
                />
            );
        }
        if (icon) {
            if (useEvIcon) {
                return <i className={`ev-icon ev-icon-${icon}`} />;
            }
            return <Icon icon={icon} />;
        }
    };

    const Element = isLink ? getLinkElement(linkType) : 'button';
    return (
        <>
            <Element
                className={classnames('button-tooltip', `is-${type}`, className, {
                    'is-regular': size === sizes.regular,
                    'is-small': size === sizes.small,
                    'is-medium': size === sizes.medium,
                    'is-large': size === sizes.large,
                    'is-link': isLink,
                    'is-disabled': isDisabled,
                    'is-image-icon': useImgIcon,
                    'is-selected': isSelected,
                    'is-indicator': isIndicator,
                    'is-control': isControl,
                    'is-icon-button': !text,
                    'use-selected-background': useSelectedBackground,
                    'use-background-hover-color': useBackgroundHoverColor,
                    [`selected-border-side-${selectedBorderSide}`]:
                        !useSelectedBackground && selectedBorderSide,
                })}
                onClick={onButtonClick}
                onMouseOver={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
                onFocus={() => setTooltipVisible(true)}
                onBlur={() => setTooltipVisible(false)}
                data-testid={testId || `${_.kebabCase(tooltip)}-button`}
                {...getButtonProps()}
            >
                {renderIcon()}
                {text && <span className="button-tooltip-text">{text}</span>}
                {_.isFinite(buttonCount) && buttonCount > 0 && (
                    <span className="button-tooltip-count">{buttonCount}</span>
                )}
                {children}
            </Element>
            {(tooltip || help) && tooltipVisible && (
                <Tooltip
                    tooltip={tooltip}
                    help={help}
                    positionType={tooltipPosition}
                    setVisible={setTooltipVisible}
                    refCurrent={ref.current}
                    offset={tooltipOffset}
                />
            )}
        </>
    );
}

ButtonTooltip.propTypes = {
    className: string,
    testId: string,
    text: string,
    icon: string,
    useEvIcon: bool,
    imgSrc: string,
    imgAlt: string,
    imgSize: number,
    useImgIcon: bool,
    tooltip: string,
    help: string,
    tooltipPosition: oneOf([
        'top',
        'top-left',
        'top-right',
        'bottom',
        'bottom-left',
        'bottom-right',
        'left',
        'right',
    ]),
    tooltipOffset: shape({
        x: number,
        y: number,
    }),
    size: oneOf(['regular', 'small', 'medium', 'large', 'custom']),
    type: oneOf(['default', 'primary', 'secondary', 'warning', 'dark']),
    isLink: bool,
    linkType: oneOf(['link', 'navlink', 'external']),
    isIndicator: bool,
    isControl: bool,
    buttonCount: number,
    isSelected: bool,
    selectedBorderSide: oneOf(['left', 'bottom']),
    useSelectedBackground: bool,
    useBackgroundHoverColor: bool,
    isDisabled: bool,
    onClick: func,
    to: string,
    stopPropagation: bool,
    preventDefault: bool,
    style: object,
};

ButtonTooltip.defaultProps = {
    useEvIcon: false,
    size: sizes.regular,
    imgSize: 22,
    type: buttonTypes.default,
    useImgIcon: false,
    isLink: false,
    linkType: linkTypes.link,
    isIndicator: false,
    isControl: false,
    isDisabled: false,
    isSelected: false,
    stopPropagation: true,
    preventDefault: true,
    useBackgroundHoverColor: false,
    selectedBorderSide: selectedBorderSides.left,
};

export default ButtonTooltip;
