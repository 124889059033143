import * as React from 'react';

const SvgTextNarrative = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M17 8.65H7.05a.5.5 0 1 1 0-1H17a.5.5 0 1 1 0 1ZM3.75 13.2a.49.49 0 0 0 .19.04.5.5 0 0 0 .36-.85l-1.53-1.53L4.3 9.33a.5.5 0 0 0-.355-.848.5.5 0 0 0-.355.148l-1.88 1.88a.48.48 0 0 0 0 .7l1.88 1.88a.49.49 0 0 0 .16.11ZM19.7 13.09a.51.51 0 0 0 .36.15.49.49 0 0 0 .35-.15l1.88-1.88a.481.481 0 0 0 0-.7l-1.88-1.88a.5.5 0 0 0-.71 0 .5.5 0 0 0 0 .7l1.53 1.53-1.53 1.53a.5.5 0 0 0 0 .7ZM9.6 17.88a.51.51 0 1 0 0-1.02.51.51 0 0 0 0 1.02ZM12.51 17.37a.51.51 0 1 1-1.02 0 .51.51 0 0 1 1.02 0ZM14.4 17.88a.51.51 0 1 0 0-1.02.51.51 0 0 0 0 1.02ZM7.05 11.36H17a.5.5 0 0 0 0-1H7.05a.5.5 0 0 0 0 1ZM7.05 14.07H17a.5.5 0 0 0 0-1H7.05a.5.5 0 0 0 0 1Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgTextNarrative;
