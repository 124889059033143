import * as React from 'react';

const SvgSingleFlipCard = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.38 21.92H11a.52.52 0 0 0 .33-.09L20 13a.5.5 0 0 0 .18-.542V3.5a1.5 1.5 0 0 0-1.5-1.5H4.38a1.5 1.5 0 0 0-1.5 1.5v16.92a1.5 1.5 0 0 0 1.5 1.5Zm14.8-9.82V3.5a.5.5 0 0 0-.5-.5H4.38a.5.5 0 0 0-.5.5v16.92a.5.5 0 0 0 .5.5h6.12V13.6a1.5 1.5 0 0 1 1.5-1.5h7.18Zm-7.53 1.15a.51.51 0 0 1 .35-.15h6.5l-7 7.1v-6.6a.51.51 0 0 1 .15-.35Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgSingleFlipCard;
