import React from 'react';

const SvgSyncOn = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.799l1.46 1.46A7.931 7.931 0 0 0 20 12c0-4.42-3.58-8-8-8Zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74A7.931 7.931 0 0 0 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3Z" />
    </svg>
);
export default SvgSyncOn;
