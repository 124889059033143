import React from 'react';

const SvgTracking = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M5 5h2v3h10v-3h2v5h2v-5c0-1.1-0.9-2-2-2h-4.18c-0.42-1.16-1.52-2-2.82-2s-2.4 0.84-2.82 2h-4.18c-1.1 0-2 0.9-2 2v14c0 1.1 0.9 2 2 2h6v-2h-6v-14zM12 3c0.55 0 1 0.45 1 1s-0.45 1-1 1-1-0.45-1-1 0.45-1 1-1z" />
        <path d="M21 11.5l-5.49 5.5-3.010-3-1.5 1.5 4.51 4.5 6.99-7z" />
    </svg>
);
export default SvgTracking;
