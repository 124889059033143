import React from 'react';

const SvgFormatClear = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M20 8V5H6.39l3 3h1.83l-.55 1.28 2.09 2.1L14.21 8H20ZM3.41 4.86 2 6.27l6.97 6.97L6.5 19h3l1.57-3.66L16.73 21l1.41-1.41L3.41 4.86Z" />
    </svg>
);
export default SvgFormatClear;
