import React from 'react';

const SvgPassword = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M2 17h20v2H2v-2Zm1.15-4.05L4 11.47l.85 1.48 1.3-.75-.85-1.48H7v-1.5H5.3l.85-1.47L4.85 7 4 8.47 3.15 7l-1.3.75.85 1.47H1v1.5h1.7l-.85 1.48 1.3.75Zm6.7-.75 1.3.75.85-1.48.849 1.48 1.3-.75-.85-1.48h1.7v-1.5h-1.7l.85-1.47-1.3-.75-.85 1.47L11.15 7l-1.3.75.85 1.47H9v1.5h1.7l-.85 1.48ZM23 9.22h-1.7l.85-1.47-1.3-.75L20 8.47 19.15 7l-1.3.75.85 1.47H17v1.5h1.7l-.85 1.48 1.3.75.85-1.48.85 1.48 1.3-.75-.85-1.48H23v-1.5Z" />
    </svg>
);
export default SvgPassword;
