import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

/**
 * @param {React.PropsWithChildren<{
 *  component: string | React.ReactElement
 *  fontStyle: 'title' | 'title-2' | 'title-3' | 'large' | 'regular' | 'small' | 'x-small' | 'micro'
 *  theme: 'light' | 'light-secondary' | 'dark' | 'dark-secondary' | 'primary' | 'warning'
 *  className?: string
 *  style?: object,
 *  spacing?: 'micro' | 'x-small' | 'small' | 'regular' | 'large' | 'x-large' | 'jumbo'
 *  fontWeight?: 'light' | 'regular' | 'bold'
 *  textAlign?: 'left' | 'center' | 'right'
 *  textDecoration?: 'underline'
 *  truncate?: boolean
 * }>} props
 */
function Typography({
    component: Component,
    fontStyle,
    children,
    className,
    style,
    theme,
    spacing,
    fontWeight,
    textAlign,
    textDecoration,
    truncate,
    ...rest
}) {
    return (
        <Component
            className={classnames(
                'typography',
                `font-style-${fontStyle}`,
                `is-${theme}`,
                spacing && `is-spacing-${spacing}`,
                fontWeight && `is-font-weight-${fontWeight}`,
                textAlign && `is-text-align-${textAlign}`,
                textDecoration && `is-text-decoration-${textDecoration}`,
                truncate && `is-truncated`,
                className
            )}
            {...rest}
            style={style}
        >
            {children}
        </Component>
    );
}

Typography.propTypes = {
    component: PropTypes.elementType.isRequired,
    fontStyle: PropTypes.oneOf([
        'title',
        'title-2',
        'title-3',
        'large',
        'regular',
        'small',
        'x-small',
        'micro',
    ]).isRequired,
    theme: PropTypes.oneOf([
        'light',
        'light-secondary',
        'dark',
        'dark-secondary',
        'primary',
        'warning',
    ]).isRequired,
    spacing: PropTypes.oneOf(['micro', 'x-small', 'small', 'regular', 'large', 'x-large', 'jumbo']),
    className: PropTypes.string,
    style: PropTypes.object,
    fontWeight: PropTypes.oneOf(['light', 'regular', 'bold']),
    textAlign: PropTypes.oneOf(['left', 'center', 'right']),
    textDecoration: PropTypes.oneOf(['underline']),
    truncate: PropTypes.bool,
};

Typography.defaultProps = {
    component: 'div',
    fontStyle: 'regular',
    theme: 'light',
    truncate: false,
};

export default Typography;
