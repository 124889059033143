import * as React from 'react';

const SvgOrdering = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3.146 7.354A.5.5 0 0 0 4 7V3a.5.5 0 0 0-.5-.5h-1a.5.5 0 1 0 0 1H3V7a.5.5 0 0 0 .146.354ZM7.5 5.5h14a.5.5 0 0 0 0-1h-14a.5.5 0 1 0 0 1ZM7.5 12.5h14a.5.5 0 0 0 0-1h-14a.5.5 0 0 0 0 1ZM21.5 19.5h-14a.5.5 0 0 1 0-1h14a.5.5 0 0 1 0 1ZM2.5 14.5A.5.5 0 0 1 2 14v-2a.5.5 0 0 1 .5-.5H4v-1H2.5a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H3v1h1.5a.5.5 0 0 1 0 1h-2ZM2.146 16.646A.5.5 0 0 1 2.5 16.5h2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1H4v-1H2.5a.5.5 0 0 1 0-1H4v-1H2.5a.5.5 0 0 1-.354-.854Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgOrdering;
