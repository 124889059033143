import React from 'react';

const SvgArrowGroup = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="m7 8 5-5 5 5H7ZM17 16l-5 5-5-5h10Z" />
    </svg>
);
export default SvgArrowGroup;
