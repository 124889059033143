import * as React from 'react';

const SvgTable = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.11 20.94h15.78a1.5 1.5 0 0 0 1.5-1.5V4.56a1.5 1.5 0 0 0-1.5-1.5H4.11a1.5 1.5 0 0 0-1.5 1.5v14.88a1.5 1.5 0 0 0 1.5 1.5Zm-.5-5.22V12.5H8.5v3.22H3.61ZM14.5 11.5h-5V8.28h5V11.5Zm0 1h-5v3.22h5V12.5Zm1 3.22V12.5h4.89v3.22H15.5Zm-1 1h-5v3.22h5v-3.22Zm1 3.22v-3.22h4.89v2.72a.5.5 0 0 1-.5.5H15.5Zm0-8.44V8.28h4.89v3.22H15.5ZM3.61 16.72H8.5v3.22H4.11a.5.5 0 0 1-.5-.5v-2.72ZM8.5 8.29v-.01H3.61v3.22H8.5V8.29ZM3.61 7.28V4.56a.5.5 0 0 1 .5-.5h15.78a.5.5 0 0 1 .5.5v2.72H3.61Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgTable;
