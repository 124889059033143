import * as React from 'react';

const SvgFlowChart = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.14 8.67a.51.51 0 0 1-.36-.15L9.06 5.8a.51.51 0 0 1 0-.71l2.72-2.72a.51.51 0 0 1 .71 0l2.72 2.72a.49.49 0 0 1 .15.36.47.47 0 0 1-.15.35l-2.72 2.72a.5.5 0 0 1-.35.15Zm-2-3.22 2 2 2-2-2-2-2 2ZM15.27 15H8.73a2.81 2.81 0 0 1 0-5.61h6.54a2.81 2.81 0 0 1 0 5.61Zm-6.54-4.58a1.81 1.81 0 0 0 0 3.61h6.54a1.81 1.81 0 0 0 0-3.61H8.73ZM10.608 21.305a2.76 2.76 0 1 0 3.064-4.589 2.76 2.76 0 0 0-3.064 4.59Zm.553-3.758c.29-.194.63-.297.979-.297A1.77 1.77 0 0 1 13.9 19a1.76 1.76 0 1 1-2.739-1.453Z"
            fill="#393B3D"
        />
        <path
            d="M4.45 13h-1a1.5 1.5 0 0 1-1.5-1.5v-5A1.5 1.5 0 0 1 3.44 5H7.5a.5.5 0 0 1 0 1H3.44a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h1a.5.5 0 0 1 .01 1ZM16.5 19.75h4.06a1.48 1.48 0 0 0 1.5-1.46v-4.85a1.49 1.49 0 0 0-1.5-1.47h-1a.5.5 0 0 0 0 1h1a.48.48 0 0 1 .5.47v4.85a.48.48 0 0 1-.5.46H16.5a.5.5 0 0 0 0 1Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgFlowChart;
