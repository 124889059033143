import * as React from 'react';

const SvgMatching = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.892 9.332a2.81 2.81 0 1 0-1.034-3.392c-1.827.166-3.386 1.64-4.74 3.437-1.303-1.73-2.796-3.133-4.538-3.351V4.74a.5.5 0 0 0-.5-.5H2.55a.5.5 0 0 0-.5.5v4.52a.5.5 0 0 0 .5.5h4.53a.5.5 0 0 0 .5-.5V7.037c1.436.245 2.749 1.574 3.927 3.191-.583.845-1.125 1.725-1.633 2.549l-.094.153-.314.5c-.754 1.206-1.505 2.405-2.098 2.886a2.803 2.803 0 0 0-1.47-1.411 2.81 2.81 0 1 0 1.729 2.464c.915-.525 1.8-1.966 2.901-3.756l.082-.133c.468-.772.968-1.595 1.498-2.386A60.7 60.7 0 0 1 13.61 13.5c1.178 1.947 2.157 3.514 3.13 3.965v2.645a.5.5 0 0 0 .5.5h4.53a.5.5 0 0 0 .5-.5v-4.53a.51.51 0 0 0-.5-.5h-4.53a.5.5 0 0 0-.5.5v.679c-.604-.57-1.337-1.761-2.08-2.967L14.48 13c-.546-.903-1.13-1.868-1.758-2.784 1.179-1.61 2.494-2.935 3.928-3.179a2.809 2.809 0 0 0 1.242 2.295Zm.555-3.832a1.81 1.81 0 1 1 2.026 3 1.81 1.81 0 0 1-2.026-3ZM3.807 16a1.81 1.81 0 1 1 2.026 3 1.81 1.81 0 0 1-2.026-3ZM3.08 8.76h3.5V5.24H3.05l.03 3.52ZM21.3 19.61h-3.53l-.03-3.53h3.56v3.53Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgMatching;
