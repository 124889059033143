import * as React from 'react';

const SvgNarrative = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M13.92 6.36a.67.67 0 1 1-1.34 0 .67.67 0 0 1 1.34 0Z" fill="#393B3D" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.48 11.59h5A1.5 1.5 0 0 0 16 10.13v-5a1.5 1.5 0 0 0-1.5-1.54h-5A1.5 1.5 0 0 0 8 5.09v5a1.5 1.5 0 0 0 1.48 1.5Zm0-1h3.733L10.34 7.6 8.98 9.035v1.055a.5.5 0 0 0 .5.5Zm1.48-3.78 3.625 3.769a.501.501 0 0 0 .395-.489v-5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0-.5.5v2.497l.73-.777a.86.86 0 0 1 .62-.27 1 1 0 0 1 .63.27Z"
            fill="#393B3D"
        />
        <path
            d="M3.75 13.2c.06.026.125.04.19.04a.54.54 0 0 0 .36-.14.51.51 0 0 0 0-.71l-1.53-1.53L4.3 9.33a.5.5 0 0 0-.355-.848.5.5 0 0 0-.355.148l-1.88 1.88a.48.48 0 0 0 0 .7l1.88 1.88a.49.49 0 0 0 .16.11ZM19.7 13.1a.54.54 0 0 0 .36.14.492.492 0 0 0 .35-.15l1.88-1.88a.481.481 0 0 0 0-.7l-1.88-1.88a.5.5 0 0 0-.71 0 .5.5 0 0 0 0 .7l1.53 1.53-1.53 1.53a.51.51 0 0 0 0 .71ZM9.6 19.88a.51.51 0 1 0 0-1.02.51.51 0 0 0 0 1.02ZM12.51 19.37a.51.51 0 1 1-1.02 0 .51.51 0 0 1 1.02 0ZM14.4 19.88a.51.51 0 1 0 0-1.02.51.51 0 0 0 0 1.02ZM17 14.37H7.05a.5.5 0 0 1 0-1H17a.5.5 0 0 1 0 1ZM7.05 16.78H17a.5.5 0 0 0 0-1H7.05a.5.5 0 0 0 0 1Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgNarrative;
