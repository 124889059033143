import * as React from 'react';

const SvgTextArea = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M18.64 16.89a.5.5 0 0 1-.35-.85l.7-.71a.51.51 0 0 1 .71 0 .5.5 0 0 1 0 .71l-.71.71a.5.5 0 0 1-.35.14ZM15.96 16.65a.51.51 0 0 0 .36.15.491.491 0 0 0 .35-.16l2.94-2.93a.51.51 0 0 0 0-.71.498.498 0 0 0-.71 0l-2.94 2.95a.5.5 0 0 0 0 .7Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.52 19h-17A1.5 1.5 0 0 1 2 17.52V6.28a1.5 1.5 0 0 1 1.5-1.5h17a1.5 1.5 0 0 1 1.5 1.5v11.24A1.5 1.5 0 0 1 20.52 19ZM3.48 5.78a.5.5 0 0 0-.5.5v11.24a.5.5 0 0 0 .5.5h17a.5.5 0 0 0 .5-.5V6.28a.5.5 0 0 0-.5-.5h-17Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgTextArea;
