import * as React from 'react';

const SvgSelect = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M18.65 13h-9.2a.5.5 0 0 1 0-1h9.2a.5.5 0 0 1 0 1ZM9.45 16h9.2a.5.5 0 0 0 0-1h-9.2a.5.5 0 0 0 0 1ZM18.65 19h-9.2a.5.5 0 0 1 0-1h9.2a.5.5 0 0 1 0 1ZM16.551 7.425a.49.49 0 0 0 .189.035.5.5 0 0 0 .36-.14l1.91-1.91a.502.502 0 0 0-.71-.71l-1.56 1.56-1.55-1.56a.51.51 0 0 0-.71 0 .5.5 0 0 0 0 .71l1.91 1.91a.49.49 0 0 0 .161.105Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.06 10H3.44a1.5 1.5 0 0 1-1.5-1.5V3.47A1.5 1.5 0 0 1 3.44 2h17.12a1.5 1.5 0 0 1 1.5 1.5v17.03a1.5 1.5 0 0 1-1.5 1.47h-13a1.5 1.5 0 0 1-1.5-1.5V10Zm1 0h13.5c.172 0 .34-.03.5-.086V20.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V10Zm-.696-.96H3.44a.5.5 0 0 1-.5-.5V3.5a.5.5 0 0 1 .5-.5l17.12-.03a.5.5 0 0 1 .5.5v5.072a.5.5 0 0 1-.5.498H6.748A.51.51 0 0 0 6.56 9a.5.5 0 0 0-.196.04Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgSelect;
