import * as React from 'react';

const SvgNumberInput = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5.21 9.51h2a.5.5 0 0 1 .5.49v4.01a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h1.5v-1h-1.5a.5.5 0 0 1 0-1h1.5v-1h-1.5a.5.5 0 0 1 0-1Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.52 17.31h17a1.5 1.5 0 0 0 1.48-1.5V8.19a1.5 1.5 0 0 0-1.5-1.5h-17A1.5 1.5 0 0 0 2 8.19v7.62a1.5 1.5 0 0 0 1.52 1.5Zm-.354-9.474a.5.5 0 0 1 .354-.146h17a.5.5 0 0 1 .5.5v7.62a.5.5 0 0 1-.5.5h-17a.5.5 0 0 1-.5-.5V8.19a.5.5 0 0 1 .146-.354Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgNumberInput;
