import React from 'react';

const SvgCloudSyncOff = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#cloudSyncOff_svg__a)">
            <path d="M24 15c0-2.64-2.05-4.78-4.65-4.96A7.49 7.49 0 0 0 12 4c-1.33 0-2.57.36-3.65.97l1.49 1.49A5.39 5.39 0 0 1 12 6c3.04 0 5.5 2.46 5.5 5.5v.5H19c1.66 0 3 1.341 3 3 0 .99-.48 1.851-1.21 2.4l1.41 1.41c1.09-.92 1.8-2.27 1.8-3.81ZM4.41 3.86 3 5.27l2.77 2.77h-.42A5.995 5.995 0 0 0 0 14c0 3.31 2.69 6 6 6h11.73l2 2 1.41-1.41L4.41 3.86ZM6 18a4 4 0 1 1 0-8h1.73l8 8H6Z" />
        </g>
        <defs>
            <clipPath id="cloudSyncOff_svg__a">
                <path d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgCloudSyncOff;
