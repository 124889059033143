import React from 'react';

const SvgFullSize = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M17.07 17.657 22.728 12l-5.656-5.656v4.652H6.928V6.344L1.272 12l5.656 5.657v-4.653h10.143v4.653Z" />
    </svg>
);
export default SvgFullSize;
