import * as React from 'react';

const SvgSorting = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.85 16.9a.48.48 0 0 0-.7 0L7 20V2.75a.5.5 0 1 0-1 0V20l-3.15-3.1a.495.495 0 0 0-.7.7l4 4a.49.49 0 0 0 .54.112.47.47 0 0 0 .16-.112l4-4a.48.48 0 0 0 0-.7Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5 19.25h-2a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h2a.51.51 0 0 1 .5.5v2a.5.5 0 0 1-.5.5Zm-1.5-1h1v-1h-1v1ZM13.5 15.25h4a.5.5 0 0 0 .5-.5v-2a.51.51 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5Zm3.5-1h-3v-1h3v1ZM19.5 11.25h-6a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h6a.51.51 0 0 1 .5.5v2a.5.5 0 0 1-.5.5Zm-5.5-1h5v-1h-5v1ZM13.5 7.25h8a.5.5 0 0 0 .5-.5v-2a.51.51 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5Zm7.5-1h-7v-1h7v1Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgSorting;
