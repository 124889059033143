import React from 'react';

const SvgStrokeDotted = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M3 11h2v2H3v-2ZM7 11h2v2H7v-2ZM11 11h2v2h-2v-2ZM15 11h2v2h-2v-2ZM19 11h2v2h-2v-2Z" />
    </svg>
);
export default SvgStrokeDotted;
