import * as React from 'react';

const SvgInteractiveVideo = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5.906 8.464a.5.5 0 0 0 .854-.354V6.94h1.18a.5.5 0 1 0 0-1H6.76V4.76a.5.5 0 1 0-1 0v1.18H4.59a.5.5 0 0 0 0 1h1.17v1.17a.5.5 0 0 0 .146.354Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.26 11a4.56 4.56 0 1 1 0-9.12 4.56 4.56 0 0 1 0 9.12Zm0-8.12a3.56 3.56 0 1 0 3.56 3.56 3.55 3.55 0 0 0-3.56-3.56Z"
            fill="#393B3D"
        />
        <path
            d="M19.9 21.79H6.51A1.5 1.5 0 0 1 5 20.29v-7.85a.51.51 0 0 1 .5-.5.5.5 0 0 1 .5.5v7.85a.5.5 0 0 0 .5.5h13.4a.5.5 0 0 0 .5-.5V6.9a.5.5 0 0 0-.5-.5h-7.67a.5.5 0 0 1 0-1h7.67a1.5 1.5 0 0 1 1.5 1.5v13.39a1.5 1.5 0 0 1-1.5 1.5Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.78 16.95A.54.54 0 0 0 12 17a.47.47 0 0 0 .3-.14L16.15 14a.5.5 0 0 0 0-.81l-3.85-2.86a.5.5 0 0 0-.8.4v5.77a.51.51 0 0 0 .28.45Zm.72-1.44v-3.75L15 13.6l-2.5 1.91Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgInteractiveVideo;
