import React from 'react';

const SvgInsertTable = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M20 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Zm0 2v3H5V5h15Zm-5 14h-5v-9h5v9ZM5 10h3v9H5v-9Zm12 9v-9h3v9h-3Z" />
    </svg>
);
export default SvgInsertTable;
