import React, { useState } from 'react';
import { login } from 'api/authenticationAPI';
import { Typography } from 'uikit/typography';
import AuthForm from '../components/authForm';
import AuthFormTextInput from '../components/authFormTextInput';
import AuthFormSubmit from '../components/authFormSubmit';
import AuthFormOAuthProviders from '../components/authFormOAuthProviders';
import AuthFormPolicies from '../components/authFormPolicies';
import getRedirectURL from '../helpers/getRedirectURL';

function LoginContainer() {
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(window.authentication.authenticationError || null);
    const [hasSubmit, setHasSubmit] = useState(false);
    const canSubmit = isValidEmail && !!password.length && !hasSubmit;

    const onSubmit = async () => {
        if (!canSubmit) return;

        try {
            setHasSubmit(true);
            await login({ email, password });
            const redirectURL = getRedirectURL(true);
            window.location = redirectURL;
        } catch (error) {
            setHasSubmit(false);
            setError(error?.response?.data.message);
        }
    };

    return (
        <AuthForm error={error}>
            <AuthFormTextInput
                name="email"
                type="email"
                placeholder="Email"
                value={email}
                shouldValidate
                onChange={(email, isValid) => {
                    setEmail(email);
                    setIsValidEmail(isValid);
                }}
                onEnterPressed={onSubmit}
            />
            <AuthFormTextInput
                name="password"
                type="password"
                placeholder="Password"
                value={password}
                shouldPreventAllSpaces
                onChange={setPassword}
                onEnterPressed={onSubmit}
            />
            <AuthFormSubmit
                testId="login-button"
                text="Log In"
                canSubmit={canSubmit}
                onSubmit={onSubmit}
            />
            <Typography fontStyle="micro">
                <a href={`${window.appURL}/forgotPassword`}>Forgot Password?</a>
            </Typography>
            <AuthFormOAuthProviders />
            <AuthFormPolicies />
        </AuthForm>
    );
}

export default LoginContainer;
