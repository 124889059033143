import React from 'react';

const SvgExtensions = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M10.5 4.5c.28 0 .5.22.5.5v2h6v6h2c.28 0 .5.22.5.5 0 .281-.219.501-.5.501h-2v6h-2.12c-.68-1.75-2.39-3-4.38-3-1.99 0-3.7 1.25-4.38 3H4v-2.12c1.75-.68 3-2.39 3-4.38 0-1.99-1.24-3.7-2.99-4.38L4 7h6V5c0-.28.22-.501.5-.501Zm0-2A2.5 2.5 0 0 0 8 5H4c-1.1 0-1.99.9-1.99 2v3.8h.291a2.701 2.701 0 0 1 0 5.4h-.3V20c0 1.1.9 2 2 2h3.8v-.3a2.701 2.701 0 0 1 5.4 0v.3h3.8c1.101 0 2.001-.9 2.001-2v-4a2.5 2.5 0 0 0 0-4.999v-4c0-1.1-.9-2-2-2h-4a2.5 2.5 0 0 0-2.5-2.5L10.5 2.5Z" />
    </svg>
);
export default SvgExtensions;
