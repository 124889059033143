import * as React from 'react';

const SvgRadioGroup = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.557 9.476a2.4 2.4 0 1 0 2.666-3.991 2.4 2.4 0 0 0-2.666 3.99Zm.554-3.15A1.4 1.4 0 1 1 6.67 8.654 1.4 1.4 0 0 1 5.11 6.327Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.44 11.52h17.12a1.5 1.5 0 0 0 1.5-1.52V5a1.5 1.5 0 0 0-1.5-1.5H3.44A1.5 1.5 0 0 0 1.94 5v5a1.5 1.5 0 0 0 1.5 1.52Zm-.354-6.924a.5.5 0 0 1 .354-.146l17.12.05a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H3.44a.5.5 0 0 1-.5-.5V4.95a.5.5 0 0 1 .146-.354ZM4.556 18.505a2.4 2.4 0 1 0 2.668-3.99 2.4 2.4 0 0 0-2.668 3.99Zm.556-3.15a1.4 1.4 0 1 1 1.556 2.33 1.4 1.4 0 0 1-1.556-2.33Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.56 20.55H3.44a1.5 1.5 0 0 1-1.5-1.5V14a1.5 1.5 0 0 1 1.5-1.5h17.12a1.5 1.5 0 0 1 1.5 1.5v5.07a1.5 1.5 0 0 1-1.5 1.48ZM3.44 13.48a.5.5 0 0 0-.5.5v5.07a.5.5 0 0 0 .5.5h17.12a.5.5 0 0 0 .5-.5V14a.5.5 0 0 0-.5-.5l-17.12-.02Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgRadioGroup;
