import * as React from 'react';

const SvgCapture = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5.35 15.715h1.9a.5.5 0 0 0 .5-.51.5.5 0 0 0-.5-.5h-1.4v-1.49a.5.5 0 0 0-1 0v2a.5.5 0 0 0 .5.5ZM18.65 10.025a.5.5 0 0 1-.5-.5v-1.5h-1.4a.5.5 0 0 1 0-1h1.9a.51.51 0 0 1 .5.5v2a.51.51 0 0 1-.5.5ZM16.75 15.715h1.9a.5.5 0 0 0 .5-.49v-2a.5.5 0 1 0-1 0v1.49h-1.4a.5.5 0 0 0 0 1ZM5.35 10.025a.51.51 0 0 1-.5-.5v-2a.51.51 0 0 1 .5-.5h1.9a.5.5 0 0 1 0 1h-1.4v1.5a.5.5 0 0 1-.5.5Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.99 12.874a1.81 1.81 0 1 0 2-3.018 1.81 1.81 0 0 0-2 3.018Zm.557-2.177a.81.81 0 0 1 .453-.132.8.8 0 0 1 .8.81.81.81 0 1 1-1.253-.678Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.58 18.145h7.92v2.25H7.89a.51.51 0 0 0-.5.5.5.5 0 0 0 .5.5h8.22a.5.5 0 0 0 .5-.5.51.51 0 0 0-.5-.5H12.5v-2.25h7.92a1.5 1.5 0 0 0 1.5-1.5V6.105a1.5 1.5 0 0 0-1.5-1.5H3.58a1.5 1.5 0 0 0-1.5 1.5v10.54a1.5 1.5 0 0 0 1.5 1.5Zm0-1h16.84a.5.5 0 0 0 .5-.5V6.105a.5.5 0 0 0-.5-.5H3.58a.5.5 0 0 0-.5.5v10.54a.5.5 0 0 0 .5.5Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgCapture;
