import * as React from 'react';

const SvgConfidenceChart = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.907 14.127a2.551 2.551 0 0 1-3.766-1.158 2.55 2.55 0 0 1 3.796-3.075L9.87 5.97a2.55 2.55 0 1 1 4.282-.03l3.946 3.937A2.55 2.55 0 0 1 22.06 12a2.56 2.56 0 0 1-2.55 2.55 2.55 2.55 0 0 1-1.413-.427l-3.95 3.94c.265.414.405.895.403 1.387A2.55 2.55 0 0 1 12 22a2.56 2.56 0 0 1-2.16-3.95l-3.933-3.924Zm4.676-7.437a2.55 2.55 0 0 0 2.877-.03l3.927 3.927a2.548 2.548 0 0 0 0 2.826l-3.93 3.93a2.56 2.56 0 0 0-2.923-.009L6.62 13.42a2.548 2.548 0 0 0 .002-2.835l3.946-3.905.016.011Zm-4.967 6.385a.483.483 0 0 0-.044.044 1.55 1.55 0 1 1 .044-.044Zm5.217 5.329a1.562 1.562 0 0 0 .05 2.145 1.56 1.56 0 0 0 2.667-1.099 1.55 1.55 0 0 0-.41-1.07l-.01-.01a.527.527 0 0 1-.023-.025l-.004-.004A1.55 1.55 0 0 0 12 17.88v.01a1.56 1.56 0 0 0-1.091.435.51.51 0 0 1-.076.079ZM10.48 4.852c.051.26.169.502.34.703a.48.48 0 0 1 .09.075c.027.026.05.056.07.087a1.55 1.55 0 0 0 2.57-1.167A1.54 1.54 0 0 0 12 3a1.55 1.55 0 0 0-1.52 1.852Zm8.169 5.86a1.55 1.55 0 1 1 1.722 2.577 1.55 1.55 0 0 1-1.722-2.578Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgConfidenceChart;
