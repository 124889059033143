import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'uikit/typography';
import AuthFormLogo from './authFormLogo';
import AuthFormIntellumLogo from './authFormIntellumLogo';
import AuthFormError from './authFormError';

const AuthForm = ({ title, description, error, children }) => (
    <div className="authentication">
        <div className="authentication-form">
            <AuthFormLogo />
            {title && (
                <Typography fontStyle="title-3" spacing="x-small">
                    {title}
                </Typography>
            )}
            {description && (
                <Typography fontStyle="small" spacing="x-small">
                    {description}
                </Typography>
            )}
            <AuthFormError error={error} />
            {children}
        </div>
        <AuthFormIntellumLogo />
    </div>
);

AuthForm.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    error: PropTypes.string,
};

export default AuthForm;
