import * as React from 'react';

const SvgBlock = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2.5 3C2.5 2.86739 2.55268 2.74021 2.64645 2.64645C2.74021 2.55268 2.86739 2.5 3 2.5H3.9C4.03261 2.5 4.15979 2.55268 4.25355 2.64645C4.34732 2.74021 4.4 2.86739 4.4 3C4.4 3.13261 4.34732 3.25979 4.25355 3.35355C4.15979 3.44732 4.03261 3.5 3.9 3.5H3.5V3.9C3.5 4.03261 3.44732 4.15979 3.35355 4.25355C3.25979 4.34732 3.13261 4.4 3 4.4C2.86739 4.4 2.74021 4.34732 2.64645 4.25355C2.55268 4.15979 2.5 4.03261 2.5 3.9V3Z"
            fill="black"
        />
        <path
            d="M6.1 3C6.1 2.86739 6.15268 2.74021 6.24645 2.64645C6.34021 2.55268 6.46739 2.5 6.6 2.5H8.4C8.53261 2.5 8.65978 2.55268 8.75355 2.64645C8.84732 2.74021 8.9 2.86739 8.9 3C8.9 3.13261 8.84732 3.25979 8.75355 3.35355C8.65978 3.44732 8.53261 3.5 8.4 3.5H6.6C6.46739 3.5 6.34021 3.44732 6.24645 3.35355C6.15268 3.25979 6.1 3.13261 6.1 3Z"
            fill="black"
        />
        <path
            d="M10.6 3C10.6 2.86739 10.6527 2.74021 10.7464 2.64645C10.8402 2.55268 10.9674 2.5 11.1 2.5H12.9C13.0326 2.5 13.1598 2.55268 13.2536 2.64645C13.3473 2.74021 13.4 2.86739 13.4 3C13.4 3.13261 13.3473 3.25979 13.2536 3.35355C13.1598 3.44732 13.0326 3.5 12.9 3.5H11.1C10.9674 3.5 10.8402 3.44732 10.7464 3.35355C10.6527 3.25979 10.6 3.13261 10.6 3Z"
            fill="black"
        />
        <path
            d="M15.1 3C15.1 2.86739 15.1527 2.74021 15.2464 2.64645C15.3402 2.55268 15.4674 2.5 15.6 2.5H17.4C17.5326 2.5 17.6598 2.55268 17.7536 2.64645C17.8473 2.74021 17.9 2.86739 17.9 3C17.9 3.13261 17.8473 3.25979 17.7536 3.35355C17.6598 3.44732 17.5326 3.5 17.4 3.5H15.6C15.4674 3.5 15.3402 3.44732 15.2464 3.35355C15.1527 3.25979 15.1 3.13261 15.1 3Z"
            fill="black"
        />
        <path
            d="M19.6 3C19.6 2.86739 19.6527 2.74021 19.7464 2.64645C19.8402 2.55268 19.9674 2.5 20.1 2.5H21C21.1326 2.5 21.2598 2.55268 21.3536 2.64645C21.4473 2.74021 21.5 2.86739 21.5 3V3.9C21.5 4.03261 21.4473 4.15979 21.3536 4.25355C21.2598 4.34732 21.1326 4.4 21 4.4C20.8674 4.4 20.7402 4.34732 20.6464 4.25355C20.5527 4.15979 20.5 4.03261 20.5 3.9V3.5H20.1C19.9674 3.5 19.8402 3.44732 19.7464 3.35355C19.6527 3.25979 19.6 3.13261 19.6 3Z"
            fill="black"
        />
        <path
            d="M3 6.1C3.13261 6.1 3.25979 6.15268 3.35355 6.24645C3.44732 6.34021 3.5 6.46739 3.5 6.6V8.4C3.5 8.53261 3.44732 8.65978 3.35355 8.75355C3.25979 8.84732 3.13261 8.9 3 8.9C2.86739 8.9 2.74021 8.84732 2.64645 8.75355C2.55268 8.65978 2.5 8.53261 2.5 8.4V6.6C2.5 6.46739 2.55268 6.34021 2.64645 6.24645C2.74021 6.15268 2.86739 6.1 3 6.1Z"
            fill="black"
        />
        <path
            d="M21 6.1C21.1326 6.1 21.2598 6.15268 21.3536 6.24645C21.4473 6.34021 21.5 6.46739 21.5 6.6V8.4C21.5 8.53261 21.4473 8.65978 21.3536 8.75355C21.2598 8.84732 21.1326 8.9 21 8.9C20.8674 8.9 20.7402 8.84732 20.6464 8.75355C20.5527 8.65978 20.5 8.53261 20.5 8.4V6.6C20.5 6.46739 20.5527 6.34021 20.6464 6.24645C20.7402 6.15268 20.8674 6.1 21 6.1Z"
            fill="black"
        />
        <path
            d="M3 10.6C3.13261 10.6 3.25979 10.6527 3.35355 10.7464C3.44732 10.8402 3.5 10.9674 3.5 11.1V12.9C3.5 13.0326 3.44732 13.1598 3.35355 13.2536C3.25979 13.3473 3.13261 13.4 3 13.4C2.86739 13.4 2.74021 13.3473 2.64645 13.2536C2.55268 13.1598 2.5 13.0326 2.5 12.9V11.1C2.5 10.9674 2.55268 10.8402 2.64645 10.7464C2.74021 10.6527 2.86739 10.6 3 10.6Z"
            fill="black"
        />
        <path
            d="M21 10.6C21.1326 10.6 21.2598 10.6527 21.3536 10.7464C21.4473 10.8402 21.5 10.9674 21.5 11.1V12.9C21.5 13.0326 21.4473 13.1598 21.3536 13.2536C21.2598 13.3473 21.1326 13.4 21 13.4C20.8674 13.4 20.7402 13.3473 20.6464 13.2536C20.5527 13.1598 20.5 13.0326 20.5 12.9V11.1C20.5 10.9674 20.5527 10.8402 20.6464 10.7464C20.7402 10.6527 20.8674 10.6 21 10.6Z"
            fill="black"
        />
        <path
            d="M3 15.1C3.13261 15.1 3.25979 15.1527 3.35355 15.2464C3.44732 15.3402 3.5 15.4674 3.5 15.6V17.4C3.5 17.5326 3.44732 17.6598 3.35355 17.7536C3.25979 17.8473 3.13261 17.9 3 17.9C2.86739 17.9 2.74021 17.8473 2.64645 17.7536C2.55268 17.6598 2.5 17.5326 2.5 17.4V15.6C2.5 15.4674 2.55268 15.3402 2.64645 15.2464C2.74021 15.1527 2.86739 15.1 3 15.1Z"
            fill="black"
        />
        <path
            d="M21 15.1C21.1326 15.1 21.2598 15.1527 21.3536 15.2464C21.4473 15.3402 21.5 15.4674 21.5 15.6V17.4C21.5 17.5326 21.4473 17.6598 21.3536 17.7536C21.2598 17.8473 21.1326 17.9 21 17.9C20.8674 17.9 20.7402 17.8473 20.6464 17.7536C20.5527 17.6598 20.5 17.5326 20.5 17.4V15.6C20.5 15.4674 20.5527 15.3402 20.6464 15.2464C20.7402 15.1527 20.8674 15.1 21 15.1Z"
            fill="black"
        />
        <path
            d="M3 19.6C3.13261 19.6 3.25979 19.6527 3.35355 19.7464C3.44732 19.8402 3.5 19.9674 3.5 20.1V20.5H3.9C4.03261 20.5 4.15979 20.5527 4.25355 20.6464C4.34732 20.7402 4.4 20.8674 4.4 21C4.4 21.1326 4.34732 21.2598 4.25355 21.3536C4.15979 21.4473 4.03261 21.5 3.9 21.5H3C2.86739 21.5 2.74021 21.4473 2.64645 21.3536C2.55268 21.2598 2.5 21.1326 2.5 21V20.1C2.5 19.9674 2.55268 19.8402 2.64645 19.7464C2.74021 19.6527 2.86739 19.6 3 19.6Z"
            fill="black"
        />
        <path
            d="M21 19.6C21.1326 19.6 21.2598 19.6527 21.3536 19.7464C21.4473 19.8402 21.5 19.9674 21.5 20.1V21C21.5 21.1326 21.4473 21.2598 21.3536 21.3536C21.2598 21.4473 21.1326 21.5 21 21.5H20.1C19.9674 21.5 19.8402 21.4473 19.7464 21.3536C19.6527 21.2598 19.6 21.1326 19.6 21C19.6 20.8674 19.6527 20.7402 19.7464 20.6464C19.8402 20.5527 19.9674 20.5 20.1 20.5H20.5V20.1C20.5 19.9674 20.5527 19.8402 20.6464 19.7464C20.7402 19.6527 20.8674 19.6 21 19.6Z"
            fill="black"
        />
        <path
            d="M6.1 21C6.1 20.8674 6.15268 20.7402 6.24645 20.6464C6.34021 20.5527 6.46739 20.5 6.6 20.5H8.4C8.53261 20.5 8.65978 20.5527 8.75355 20.6464C8.84732 20.7402 8.9 20.8674 8.9 21C8.9 21.1326 8.84732 21.2598 8.75355 21.3536C8.65978 21.4473 8.53261 21.5 8.4 21.5H6.6C6.46739 21.5 6.34021 21.4473 6.24645 21.3536C6.15268 21.2598 6.1 21.1326 6.1 21Z"
            fill="black"
        />
        <path
            d="M10.6 21C10.6 20.8674 10.6527 20.7402 10.7464 20.6464C10.8402 20.5527 10.9674 20.5 11.1 20.5H12.9C13.0326 20.5 13.1598 20.5527 13.2536 20.6464C13.3473 20.7402 13.4 20.8674 13.4 21C13.4 21.1326 13.3473 21.2598 13.2536 21.3536C13.1598 21.4473 13.0326 21.5 12.9 21.5H11.1C10.9674 21.5 10.8402 21.4473 10.7464 21.3536C10.6527 21.2598 10.6 21.1326 10.6 21Z"
            fill="black"
        />
        <path
            d="M15.1 21C15.1 20.8674 15.1527 20.7402 15.2464 20.6464C15.3402 20.5527 15.4674 20.5 15.6 20.5H17.4C17.5326 20.5 17.6598 20.5527 17.7536 20.6464C17.8473 20.7402 17.9 20.8674 17.9 21C17.9 21.1326 17.8473 21.2598 17.7536 21.3536C17.6598 21.4473 17.5326 21.5 17.4 21.5H15.6C15.4674 21.5 15.3402 21.4473 15.2464 21.3536C15.1527 21.2598 15.1 21.1326 15.1 21Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.5 8.5C9.36739 8.5 9.24021 8.55268 9.14645 8.64645C9.05268 8.74021 9 8.86739 9 9V11.5C9 11.6326 9.05268 11.7598 9.14645 11.8536C9.24021 11.9473 9.36739 12 9.5 12H14.5C14.6326 12 14.7598 11.9473 14.8536 11.8536C14.9473 11.7598 15 11.6326 15 11.5V9C15 8.86739 14.9473 8.74021 14.8536 8.64645C14.7598 8.55268 14.6326 8.5 14.5 8.5H9.5ZM10 11V9.5H14V11H10Z"
            fill="black"
        />
        <path
            d="M9 13.22C9 13.0874 9.05268 12.9602 9.14645 12.8664C9.24021 12.7727 9.36739 12.72 9.5 12.72H14.5C14.6326 12.72 14.7598 12.7727 14.8536 12.8664C14.9473 12.9602 15 13.0874 15 13.22C15 13.3526 14.9473 13.4798 14.8536 13.5736C14.7598 13.6673 14.6326 13.72 14.5 13.72H9.5C9.36739 13.72 9.24021 13.6673 9.14645 13.5736C9.05268 13.4798 9 13.3526 9 13.22Z"
            fill="black"
        />
        <path
            d="M9.5 14.38C9.36739 14.38 9.24021 14.4327 9.14645 14.5264C9.05268 14.6202 9 14.7474 9 14.88C9 15.0126 9.05268 15.1398 9.14645 15.2336C9.24021 15.3273 9.36739 15.38 9.5 15.38H14.5C14.6326 15.38 14.7598 15.3273 14.8536 15.2336C14.9473 15.1398 15 15.0126 15 14.88C15 14.7474 14.9473 14.6202 14.8536 14.5264C14.7598 14.4327 14.6326 14.38 14.5 14.38H9.5Z"
            fill="black"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.5 7C6.5 6.86739 6.55268 6.74021 6.64645 6.64645C6.74021 6.55268 6.86739 6.5 7 6.5H17C17.1326 6.5 17.2598 6.55268 17.3536 6.64645C17.4473 6.74021 17.5 6.86739 17.5 7V17C17.5 17.1326 17.4473 17.2598 17.3536 17.3536C17.2598 17.4473 17.1326 17.5 17 17.5H7C6.86739 17.5 6.74021 17.4473 6.64645 17.3536C6.55268 17.2598 6.5 17.1326 6.5 17V7ZM7.5 7.5V16.5H16.5V7.5H7.5Z"
            fill="black"
        />
    </svg>
);
export default SvgBlock;
