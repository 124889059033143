import * as React from 'react';

const SvgText = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.78 18.43a.429.429 0 0 1-.19 0 .49.49 0 0 1-.27-.65L7.463 5.686l.003-.009a.5.5 0 0 1 .964.088l2.845 6.668a.499.499 0 0 1 .218.51l2.047 4.797a.49.49 0 0 1-.27.65.49.49 0 0 1-.505-.068.51.51 0 0 1-.215-.242l-2-4.69H5.39l-2 4.69a.5.5 0 0 1-.298.245.5.5 0 0 1-.312.105ZM7.93 7.19l2.153 5.16H5.777L7.93 7.19ZM20.62 18.09a.52.52 0 0 0 .18.212.484.484 0 0 0 .34.128.5.5 0 0 0 .46-.69l-1.285-3.014a.59.59 0 0 0-.01-.024L18.53 10.54a.502.502 0 0 0-.956.124l-1.68 3.941a.499.499 0 0 0-.098.23L14.54 17.78a.49.49 0 0 0 .27.65.429.429 0 0 0 .19 0 .49.49 0 0 0 .334-.108.502.502 0 0 0 .206-.232l1.151-2.68h2.778l1.151 2.68Zm-2.51-5.93-1 2.23H19l-.89-2.23Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgText;
