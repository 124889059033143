import * as React from 'react';

const SvgLinkedConfidenceSlider = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.558 10.303a2.86 2.86 0 0 0 1.592.477 2.87 2.87 0 0 0 2.804-2.36H22.07a.5.5 0 0 0 0-1H10.956a2.86 2.86 0 0 0-5.632 0H1.93a.5.5 0 0 0 0 1h3.394a2.86 2.86 0 0 0 1.234 1.883Zm-.276-2.295a1.859 1.859 0 1 0 0-.176.5.5 0 0 1 0 .176ZM15.85 18.94a2.86 2.86 0 0 1-2.816-2.36H1.93a.5.5 0 0 1 0-1h11.104l.01-.058a2.86 2.86 0 0 1 5.622.058h3.404a.5.5 0 0 1 0 1h-3.404a2.861 2.861 0 0 1-2.816 2.36Zm0-4.72a1.86 1.86 0 1 0 0 3.72 1.86 1.86 0 0 0 0-3.72Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgLinkedConfidenceSlider;
