import React from 'react';

const SvgWarning = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M12 5.99 19.53 19H4.47L12 5.99ZM12 2 1 21h22L12 2Zm1 14h-2v2h2v-2Zm0-6h-2v4h2v-4Z" />
    </svg>
);
export default SvgWarning;
