import React from 'react';

const SvgStop = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M6 6h12v12H6V6Z" />
    </svg>
);
export default SvgStop;
