import * as React from 'react';

const SvgSlider = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2.65 8.37a.5.5 0 0 1-.5-.5V6.61a.5.5 0 0 1 1 0v1.26a.5.5 0 0 1-.5.5Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.598 17.249a3.8 3.8 0 0 0 2.112.641 3.79 3.79 0 0 0 3.767-3.29h9.243a.5.5 0 0 0-.01-1h-9.232a3.8 3.8 0 0 0-7.536 0H2.28a.5.5 0 0 0 0 1h2.664a3.8 3.8 0 0 0 1.654 2.649Zm-.687-3.064a2.8 2.8 0 0 0 5.598.007.502.502 0 0 1 0-.185A2.792 2.792 0 0 0 8.71 11.3a2.8 2.8 0 0 0-2.799 2.708.5.5 0 0 1 0 .177Z"
            fill="#393B3D"
        />
        <path
            d="M6.006 8.224a.5.5 0 0 0 .854-.354V6.61a.5.5 0 0 0-1 0v1.26a.5.5 0 0 0 .146.354ZM10.07 8.37a.5.5 0 0 1-.5-.5V6.61a.5.5 0 0 1 1 0v1.26a.5.5 0 0 1-.5.5ZM13.437 8.224a.5.5 0 0 0 .853-.354V6.61a.5.5 0 0 0-1 0v1.26a.5.5 0 0 0 .147.354ZM17.5 8.37a.5.5 0 0 1-.5-.5V6.61a.5.5 0 0 1 1 0v1.26a.5.5 0 0 1-.5.5ZM20.856 8.224a.5.5 0 0 0 .854-.354V6.61a.5.5 0 0 0-1 0v1.26a.5.5 0 0 0 .146.354Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgSlider;
