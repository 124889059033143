import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import { Icon } from '../../icons';

const buttonTypes = {
    default: 'default',
    primary: 'primary',
    primaryInverted: 'primary-inverted',
    secondary: 'secondary',
    tertiary: 'tertiary',
    warning: 'warning',
    dark: 'dark',
};

const linkTypes = {
    link: 'link',
    navlink: 'navlink',
    external: 'external',
};

const iconPositions = {
    right: 'right',
    left: 'left',
};

const iconTypes = {
    default: 'default',
    warning: 'warning',
    success: 'success',
};

/**
 * @param {React.PropsWithChildren<{
 *  className?: string
 *  testId?: string
 *  text: string
 *  icon?: string
 *  iconPosition?: 'left' | 'right'
 *  iconType?: 'default' | 'success' | 'warning'
 *  type: 'default' | 'primary' | 'secondary' | 'tertiary' | 'warning' | 'dark'
 *  isFlat?: boolean
 *  isLink?: boolean
 *  linkType?: 'link' | 'navlink' | 'external';
 *  isSmall?: boolean
 *  isLarge?: boolean
 *  isFullWidth?: boolean
 *  isDisabled: boolean
 *  isActive?: boolean
 *  onClick?: function
 *  to?: string
 * }>} props
 */
function Button({
    className,
    testId,
    text,
    icon,
    iconPosition,
    iconType,
    type,
    isFlat,
    isLink,
    isSmall,
    isLarge,
    isFullWidth,
    isDisabled,
    isActive,
    onClick,
    to,
    linkType,
    children,
}) {
    const onButtonClick = event => {
        event.preventDefault();
        event.currentTarget.blur();
        event.stopPropagation();
        onClick?.(event);
    };

    let Element = 'button';
    if (isLink) {
        if (linkType === linkTypes.external) {
            Element = 'a';
        } else if (linkType === linkTypes.navlink) {
            Element = NavLink;
        } else {
            Element = Link;
        }
    }

    const buttonProps = {};

    if (isLink) {
        if (linkType === linkTypes.external) {
            buttonProps.href = to;
            buttonProps.target = '_blank';
            buttonProps.rel = 'noreferrer';
        } else {
            buttonProps.to = to;
            if (linkType === linkTypes.navlink) {
                buttonProps.activeClassName = 'is-selected';
                buttonProps.exact = false;
            }
        }
    } else {
        buttonProps.disabled = isDisabled;
        buttonProps.onClick = onButtonClick;
    }

    return (
        <Element
            className={classnames('button', className, {
                'is-default': type === buttonTypes.default,
                'is-primary': type === buttonTypes.primary,
                'is-primary-inverted': type === buttonTypes.primaryInverted,
                'is-secondary': type === buttonTypes.secondary,
                'is-tertiary': type === buttonTypes.tertiary,
                'is-warning': type === buttonTypes.warning,
                'is-dark': type === buttonTypes.dark,
                'is-disabled': isDisabled,
                'is-flat': isFlat,
                'is-small': isSmall,
                'is-large': isLarge,
                'is-full-width': isFullWidth,
                'is-icon-left': iconPosition === iconPositions.left,
                'is-icon-right': iconPosition === iconPositions.right,
            })}
            disabled={isDisabled}
            data-testid={testId || `${_.kebabCase(text)}-button`}
            {...buttonProps}
        >
            {icon && iconPosition === iconPositions.left && (
                <Icon className={`icon-type-${iconType}`} icon={icon} />
            )}
            <span className="button-text">{text}</span>
            {icon && iconPosition === iconPositions.right && (
                <Icon className={`icon-type-${iconType}`} icon={icon} />
            )}
            {isActive && <Icon icon="check" className="button-active-icon" />}
            {children}
        </Element>
    );
}

Button.propTypes = {
    className: PropTypes.string,
    testId: PropTypes.string,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    iconType: PropTypes.oneOf(['default', 'success', 'warning']),
    type: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary', 'warning', 'dark']),
    isFlat: PropTypes.bool.isRequired,
    isLink: PropTypes.bool,
    linkType: PropTypes.oneOf(['link', 'navlink', 'external']),
    isSmall: PropTypes.bool,
    isLarge: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    isDisabled: PropTypes.bool.isRequired,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    to: PropTypes.string,
};

Button.defaultProps = {
    type: buttonTypes.primary,
    isDisabled: false,
    isLink: false,
    linkType: linkTypes.link,
    isFlat: false,
    isFullWidth: false,
    isSmall: false,
    isLarge: false,
    isActive: false,
    iconPosition: iconPositions.left,
    iconType: iconTypes.default,
};

export default Button;
