import React from 'react';

const SvgFolderZip = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2Zm-4 10h2v-2h-2v-2h2V9.998h-2v-2h4v10h-4v-2Zm0 0h-2v2H4V6h5.17l2 2H14v2h2v2h-2v2.001h2v2V16Z" />
    </svg>
);
export default SvgFolderZip;
