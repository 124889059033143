import * as React from 'react';

const SvgSimulation = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.722 2.042a.5.5 0 0 0-.404 0l-9.017 3.98a.494.494 0 0 0-.281.318.5.5 0 0 0-.02.151V17.48a.5.5 0 0 0 .297.457l8.942 3.966.008.006a.5.5 0 0 0 .283.08.5.5 0 0 0 .283-.08l.008-.006 8.942-3.966a.5.5 0 0 0 .297-.457V6.49a.497.497 0 0 0-.302-.47l-9.036-3.978Zm7.602 4.44L11.52 3.046 3.735 6.482l7.795 3.466 7.794-3.466Zm-7.284 4.334v9.896l8.02-3.557V7.25l-8.02 3.566ZM3 7.25l8.02 3.566v9.896L3 17.155V7.25Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgSimulation;
