import * as React from 'react';

const SvgDialog = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2.195 20.4a.5.5 0 0 0 .305.1 9.001 9.001 0 0 0 3.8-1.12 19.48 19.48 0 0 0 2.23-1.31c.979.26 1.987.39 3 .39a.5.5 0 0 0 0-1 10.867 10.867 0 0 1-3-.41.48.48 0 0 0-.42.07 17.2 17.2 0 0 1-3.74 2 6.76 6.76 0 0 0 1.58-2.79.49.49 0 0 0-.19-.54A6 6 0 0 1 3 11c0-3.58 3.81-6.5 8.5-6.5a10.1 10.1 0 0 1 5.62 1.64 6.27 6.27 0 0 1 2.78 3.95.508.508 0 0 0 1-.18 7.22 7.22 0 0 0-3.2-4.6 11.1 11.1 0 0 0-6.2-1.81 10.89 10.89 0 0 0-6.67 2.16A7.48 7.48 0 0 0 2.77 8 6.11 6.11 0 0 0 2 11a6.85 6.85 0 0 0 2.88 5.39 6.2 6.2 0 0 1-2.64 3.18.5.5 0 0 0-.045.83Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.25 18.29a6.52 6.52 0 0 0 3.25 1.21.5.5 0 0 0 .46-.702.49.49 0 0 0-.12-.168 4.77 4.77 0 0 1-1.15-1.53A3.42 3.42 0 0 0 22 14.5a3.54 3.54 0 0 0-1.68-2.88 6.67 6.67 0 0 0-3.82-1.12 6.69 6.69 0 0 0-3.82 1.12A3.54 3.54 0 0 0 11 14.5a3.54 3.54 0 0 0 1.68 2.88 6.69 6.69 0 0 0 3.82 1.12 7.232 7.232 0 0 0 1.75-.21Zm.41-.94a.57.57 0 0 0-.31-.1v.02h-.13c-.56.153-1.139.23-1.72.23a5.71 5.71 0 0 1-3.25-.93A2.58 2.58 0 0 1 12 14.5a2.58 2.58 0 0 1 1.25-2.09 5.71 5.71 0 0 1 3.25-.93 5.73 5.73 0 0 1 3.25.93A2.61 2.61 0 0 1 21 14.48a2.531 2.531 0 0 1-1.14 2 .53.53 0 0 0-.18.28c-.05.19-.07.64.48 1.44a7.263 7.263 0 0 1-1.5-.85Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgDialog;
