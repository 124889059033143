import React from 'react';

const SvgWidthTwothirdsRight = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M4 18h2V6H4v12ZM2 5a1 1 0 0 1 1-1h5v16H3a1 1 0 0 1-1-1V5Zm10 1v12h8V6h-8Zm-2 14V4h11a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H10Z" />
    </svg>
);
export default SvgWidthTwothirdsRight;
