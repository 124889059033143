import React from 'react';

const SvgTag = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7v10c0 1.1.9 1.99 2 1.99l11 .009c.67 0 1.27-.33 1.63-.84l4.37-6.16-4.37-6.16v.001ZM16 17H5V7h11l3.55 5L16 17Z" />
    </svg>
);
export default SvgTag;
