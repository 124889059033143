import React from 'react';

const SvgWidthTwothirdsLeft = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M4 18h8V6H4v12ZM2 5a1 1 0 0 1 1-1h11v16H3a1 1 0 0 1-1-1V5Zm16 1v12h2V6h-2Zm-2 14V4h5a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-5Z" />
    </svg>
);
export default SvgWidthTwothirdsLeft;
