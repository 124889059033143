import React from 'react';

const SvgInsertIcon = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="m12 7.13 1.44 3.4 1.2.1 2.47.21-2.79 2.42.27 1.18.56 2.41-2.12-1.28-1.03-.64-1.03.62-2.12 1.28.56-2.41.27-1.18-2.79-2.42 3.67-.31.47-1.11.97-2.27ZM12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.641-7.03 5.46-4.73-7.19-.61L11.999 2H12Z" />
    </svg>
);
export default SvgInsertIcon;
