import React from 'react';

const SvgProfile = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M13.17 4 18 8.83V20H6V4h7.17ZM14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6Zm-2 12c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm4 3.43c0-.81-.48-1.53-1.22-1.85a6.954 6.954 0 0 0-5.561 0c-.74.32-1.22 1.04-1.22 1.85V18h8v-.57Z" />
    </svg>
);
export default SvgProfile;
