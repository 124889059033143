import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ContextMenu } from '../../contextMenu';
import Button from './button';

/**
 * @param {React.PropsWithChildren<{
 *  className?: string
 *  text: string
 *  onClick: function
 *  contextMenuProps?: object
 * }>} props
 */
function ButtonSegmented({ icon, className, text, onClick, isDisabled, contextMenuProps }) {
    return (
        <div className={classnames('button-segmented', className)}>
            <Button
                icon={icon}
                text={text}
                type="primary"
                onClick={onClick}
                isDisabled={isDisabled}
            />
            <ContextMenu buttonSize="large" {...contextMenuProps} />
        </div>
    );
}

ButtonSegmented.propTypes = {
    icon: PropTypes.string,
    isDisabled: PropTypes.bool,
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    contextMenuProps: PropTypes.object,
};

export default ButtonSegmented;
