import * as React from 'react';

const SvgCode = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.58 16.75c.07.03.144.048.22.05a.501.501 0 0 0 .45-.27l4.39-8.6a.501.501 0 0 0-.22-.68.51.51 0 0 0-.67.22l-4.39 8.6a.5.5 0 0 0 .22.68ZM6.83 16.8a.469.469 0 0 1-.35-.15l-4.31-4.3a.5.5 0 0 1 0-.7l4.31-4.3a.495.495 0 0 1 .7.7L3.24 12l3.94 4a.5.5 0 0 1-.35.85v-.05ZM17.17 16.8a.5.5 0 0 1-.35-.85L20.76 12l-3.94-3.95a.495.495 0 0 1 .7-.7l4.31 4.3a.5.5 0 0 1 0 .7l-4.31 4.3a.47.47 0 0 1-.35.15Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgCode;
