import React from 'react';

const SvgGrid = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M4 8h4v-4h-4v4zM10 20h4v-4h-4v4zM4 20h4v-4h-4v4zM4 14h4v-4h-4v4zM10 14h4v-4h-4v4zM16 4v4h4v-4h-4zM10 8h4v-4h-4v4zM16 14h4v-4h-4v4zM16 20h4v-4h-4v4z" />
    </svg>
);
export default SvgGrid;
