import React from 'react';

const SvgSyncOff = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M10 6.35V4.26c-.66.17-1.29.43-1.88.75l1.5 1.5c.13-.05.25-.11.38-.16ZM20 12c0-2.21-.91-4.2-2.36-5.64L20 4h-6v6l2.24-2.24A6.003 6.003 0 0 1 18 12c0 .849-.189 1.65-.51 2.379l1.5 1.5a7.923 7.923 0 0 0 1.011-3.88V12ZM4.27 4 2.86 5.41l2.36 2.36a7.923 7.923 0 0 0 1.14 9.87L4 20h6v-6l-2.24 2.24A6.003 6.003 0 0 1 6 12c0-.999.249-1.94.68-2.769l8.08 8.08c-.25.13-.501.24-.76.34v2.09c.8-.21 1.55-.54 2.23-.96l2.58 2.58 1.41-1.41L4.268 4V4Z" />
    </svg>
);
export default SvgSyncOff;
