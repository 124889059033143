import React from 'react';

const SvgMove = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M11 9.575v-4.35l-1.85 1.85L7.7 5.65l4.3-4.3 4.3 4.3-1.45 1.425L13 5.226v4.35h-2Zm1 13.075-4.425-4.425 1.45-1.425 1.974 1.975V14.4h2v4.375l1.976-1.975 1.45 1.425-4.426 4.425H12Zm6.35-6.35-1.425-1.45 1.85-1.85h-4.35v-2h4.35l-1.85-1.85L18.35 7.7l4.3 4.3-4.3 4.3Zm-12.7 0L1.35 12l4.325-4.325L7.1 9.125l-1.875 1.874H9.6v2H5.225l1.85 1.851-1.424 1.45H5.65Z" />
    </svg>
);
export default SvgMove;
