import * as React from 'react';

const SvgBranching = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5 7.822a2.65 2.65 0 1 1 1 0v3.368h5.96A1.57 1.57 0 0 1 20 12.76v1.982a2.648 2.648 0 0 1 2.18 2.618A2.64 2.64 0 0 1 19.53 20a2.65 2.65 0 0 1-.51-5.25l.01-.002V12.76a.58.58 0 0 0-.57-.57H12.5v2.558a2.65 2.65 0 1 1-1.01.002l.01-.002V12.19H5.54a.58.58 0 0 0-.57.57v1.99a2.657 2.657 0 0 1 1.698 1.134 2.65 2.65 0 1 1-2.723-1.134l.025-.004V12.76a1.57 1.57 0 0 1 1.57-1.57h5.96V7.822Zm.413-.944a1.65 1.65 0 1 1 .174 0 .5.5 0 0 0-.174 0Zm-.036 8.837a1.65 1.65 0 1 0 1.773 1.645A1.65 1.65 0 0 0 12 15.73a.502.502 0 0 1-.123-.015Zm-6.254.495a1.65 1.65 0 0 0-1.152-.48H4.47a.501.501 0 0 1-.125-.016A1.65 1.65 0 1 0 6.11 17.36a1.65 1.65 0 0 0-.487-1.15Zm12.99-.222a1.65 1.65 0 0 1 .794-.273c.04.01.081.015.123.015a1.64 1.64 0 0 1 1.65 1.63 1.65 1.65 0 1 1-2.567-1.372Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgBranching;
