import * as React from 'react';

const SvgAudio = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.93 19.23a1 1 0 0 0 .61.23.93.93 0 0 0 .4-.11.94.94 0 0 0 .55-.86v-13a.95.95 0 0 0-1.56-.72L5.682 8.41H3.76a1.5 1.5 0 0 0-1.5 1.5v4.17a1.5 1.5 0 0 0 1.5 1.5h1.91l4.26 3.65Zm-3.58-4.427 4.19 3.587V5.61L6.35 9.156v5.647ZM3.76 9.41a.5.5 0 0 0-.5.5v4.17a.5.5 0 0 0 .5.5h1.59V9.41H3.76Z"
            fill="#393B3D"
        />
        <path
            d="M18.23 18.83a.489.489 0 0 1-.38-.18.492.492 0 0 1 .07-.7 7.691 7.691 0 0 0 0-11.9.5.5 0 1 1 .63-.77 8.67 8.67 0 0 1 0 13.44.46.46 0 0 1-.32.11Z"
            fill="#393B3D"
        />
        <path
            d="M16.27 16.44a.5.5 0 0 1-.31-.89 4.54 4.54 0 0 0 0-7.1.5.5 0 1 1 .63-.78 5.55 5.55 0 0 1 0 8.66.52.52 0 0 1-.32.11Z"
            fill="#393B3D"
        />
        <path
            d="M14.056 13.978a.5.5 0 0 0 .284.092.45.45 0 0 0 .31-.11 2.508 2.508 0 0 0 0-3.92.5.5 0 0 0-.755.621.49.49 0 0 0 .125.149 1.53 1.53 0 0 1 0 2.38.5.5 0 0 0 .036.788Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgAudio;
