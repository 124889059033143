import React from 'react';

const SvgColors = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M16.56 8.94 7.62 0 6.21 1.41l2.38 2.38-5.15 5.15a1.49 1.49 0 0 0 0 2.12l5.5 5.5c.29.29.68.44 1.059.44.38 0 .77-.15 1.06-.44l5.5-5.5c.59-.58.59-1.53 0-2.12h.001ZM5.21 10 10 5.21 14.79 10H5.212ZM19 11.5s-2 2.17-2 3.5c0 1.1.9 2 2 2s2-.9 2-2c0-1.33-2-3.5-2-3.5ZM2 20h20v4H2v-4Z" />
    </svg>
);
export default SvgColors;
