import * as React from 'react';

const SvgHotgraphic = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.27 8.61a.5.5 0 0 1-.5-.5V6.94H4.59a.5.5 0 0 1 0-1h1.18V4.76a.5.5 0 1 1 1 0v1.18h1.17a.5.5 0 0 1 0 1H6.77v1.17a.5.5 0 0 1-.5.5Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.734 10.236a4.56 4.56 0 1 0 5.052-7.593 4.56 4.56 0 0 0-5.052 7.593Zm.555-6.76a3.56 3.56 0 1 1 3.942 5.929 3.56 3.56 0 0 1-3.942-5.93Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.51 21.79H19.9a1.5 1.5 0 0 0 1.5-1.5V6.9a1.5 1.5 0 0 0-1.5-1.5h-7.67a.5.5 0 0 0 0 1h7.67a.5.5 0 0 1 .5.5v13.23L14.27 14a1.49 1.49 0 0 0-2.12 0L6 20.095V12.44a.5.5 0 0 0-.5-.5.51.51 0 0 0-.5.5v7.85a1.5 1.5 0 0 0 1.51 1.5Zm.214-1H19.64l-6.08-6.08a.5.5 0 0 0-.71 0l-6.126 6.08Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.592 11.987c.34.226.74.345 1.148.343a2.05 2.05 0 0 0 2.05-2.05 2.06 2.06 0 1 0-3.198 1.707Zm.712-2.718a1.06 1.06 0 1 1 .666 2.012 1.06 1.06 0 0 1-.666-2.012Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgHotgraphic;
