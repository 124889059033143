import * as React from 'react';

const SvgGmcq = props => (
    <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M8.29 5.91a.81.81 0 1 0 0-1.62.81.81 0 0 0 0 1.62Z" fill="#393B3D" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 11.33a1.5 1.5 0 0 0 1.5-1.5V3.4A1.5 1.5 0 0 0 10 1.9H3.56a1.5 1.5 0 0 0-1.5 1.5v4.58a.502.502 0 0 0 0 .17v1.68a1.5 1.5 0 0 0 1.5 1.5H10Zm-.144-1L5.44 5.72a1 1 0 0 0-1.37 0L3.06 6.794V3.4a.5.5 0 0 1 .5-.5H10a.5.5 0 0 1 .5.5v6.43a.5.5 0 0 1-.5.5h-.144ZM3.06 8.254V9.83a.5.5 0 0 0 .5.5h4.916L4.72 6.41 3.06 8.254Z"
            fill="#393B3D"
        />
        <path
            d="M13.59 7.11h7.95a.5.5 0 1 0 .05-1h-8a.5.5 0 1 0 0 1ZM8.29 16.48a.81.81 0 1 0 0-1.62.81.81 0 0 0 0 1.62Z"
            fill="#393B3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.65 21.9H3.56a1.5 1.5 0 0 1-1.5-1.5V14a1.5 1.5 0 0 1 1.5-1.5H10a1.5 1.5 0 0 1 1.5 1.5v6.4a1.5 1.5 0 0 1-1.5 1.5H9.65Zm-1.178-1H3.56a.5.5 0 0 1-.5-.5v-1.556L4.72 17l3.752 3.9Zm1.376 0L5.46 16.29a1 1 0 0 0-.68-.29 1 1 0 0 0-.69.3l-1.03 1.096V13.97a.5.5 0 0 1 .5-.5l6.44.03a.5.5 0 0 1 .5.5v6.4a.5.5 0 0 1-.5.5h-.152Z"
            fill="#393B3D"
        />
        <path
            d="M13.59 17.69h7.95a.5.5 0 0 0 .404-.854.5.5 0 0 0-.354-.146h-8a.5.5 0 0 0 0 1Z"
            fill="#393B3D"
        />
    </svg>
);
export default SvgGmcq;
