import React from 'react';

const SvgSubscript = props => (
    <svg
        viewBox="0 0 24 24"
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M22 19h-2v1h3v1h-4v-2.001c0-.55.45-1 1-1h2v-1h-3v-1h3c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1ZM5.88 19h2.66l3.4-5.42h.12l3.4 5.42h2.66l-4.65-7.27L17.81 5h-2.68l-3.07 4.99h-.12L8.85 5H6.19l4.32 6.73L5.88 19Z" />
    </svg>
);
export default SvgSubscript;
